import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Badge,
  Row,
  Table,
  Input,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Form,
} from "reactstrap"
import { isEmpty, map, set, tap, toPairs } from "lodash"
//Import Breadcrumb
// import Breadcrumbs from "../../components/Common/Breadcrumb"

//Import Image
// import logo from "../../assets/images/Pham logo.png"
// import logo from "../../assets/images/ecs.png"
import logo from "../../assets/images/ecs.png"
// import logoLight from "../../assets/images/logo-light.png"
// import { getCourierDetail as onGetCourierDetail } from "../../store/invoices/actions"
//redux
import { useSelector, useDispatch } from "react-redux"
import axios from "axios"

import toastr from "toastr"
import "toastr/build/toastr.min.css"
import { use } from "i18next"
import apiURl from "url"

const CourierDetail = props => {
  //meta title
  document.title = "ECS | Courier Detail"
  const { id } = useParams()
  const CID = id
  //Print

  const [printdata, setPrintdata] = useState("")

  const printInvoice = () => {
    const content = document.getElementById("print-data").innerHTML
    setPrintdata(content)
    console.log(content)
    axios
      .post(`${apiURl}/print`, { content })
      .then(response => {
        console.log(response.data)
      })
      .catch(error => {
        console.error("Error:", error)
      })
  }

  const navigate = useNavigate()

  const FullName = localStorage.getItem("full_name")
  const login_user_id = localStorage.getItem("login_user_id")
  const role = localStorage.getItem("role")
  const city = localStorage.getItem("city")

  const [trackId, setTrackId] = useState([])
  const [ParcelStatus, setParcelStatus] = useState([])

  const [Parcelinfo, SetParcelinfo] = useState([])
  const [orderNo, setOrderNo] = useState([])
  const [sname, setSname] = useState(null)
  const [sphone, setSphone] = useState(null)
  const [saddress, setSaddress] = useState(null)
  const [rname, setRname] = useState(null)
  const [rphone, setRphone] = useState(null)
  const [raddress, setRaddress] = useState(null)

  const [status, setStatus] = useState([])
  const [Edit, SetEdit] = useState(false)
  const [changedstatus, setChangedstatus] = useState("")

  const [assignStatus, setAssignStatus] = useState([])
  const [EditAssign, SetEditAssign] = useState(false)
  const [changeassignStatus, setChangeAssignStatus] = useState([])

  const [service, setService] = useState(null)
  const [DocSubService, setDocSubService] = useState(null)
  const [subservice, setSubservice] = useState(null)
  const [sector, setSector] = useState(null)

  const [DocumentMode, setDocumentMode] = useState(false)
  const [riders, setRiders] = useState([])
  const [RiderCode, setRiderCode] = useState([])

  const [documentDetail, setDocumentDetail] = useState(null)

  const [employee, setEmployee] = useState(null)

  //Dispatch Detail
  const [LocationModal, setLocationModal] = useState(false)
  const [fromCity, setFromCity] = useState(null)
  const [ToCity, setToCity] = useState(null)

  const fetchParcelinfoByID = () => {
    const URL = `${apiURl}/fetchParcelDetailsByID`
    const id = { id: CID } // Wrap the id in an object
    axios
      .post(URL, id)
      .then(response => {
        if (response.data.status === "success") {
          setTrackId(response.data.parcelDetailsByID[0].track_id)
          setSname(response.data.parcelDetailsByID[0].sender_full_name)
          setSphone(response.data.parcelDetailsByID[0].sender_phone)
          setSaddress(response.data.parcelDetailsByID[0].sender_address)
          setRname(response.data.parcelDetailsByID[0].receiver_full_name)
          setRphone(response.data.parcelDetailsByID[0].receiver_phone)
          setRaddress(response.data.parcelDetailsByID[0].receiver_address)
          SetParcelinfo(response.data.parcelDetailsByID)
          setDocumentDetail(response.data.parcelDetailsByID[0].docs_detail)
          //Status
          setStatus(response.data.parcelDetailsByID[0].status_)
          setChangedstatus(response.data.parcelDetailsByID[0].status_)
          //Service Name
          setService(response.data.parcelDetailsByID[0].service_name)
          setDocSubService(response.data.parcelDetailsByID[0].doc_sub_service)
          setSubservice(response.data.parcelDetailsByID[0].sub_service)
          setSector(response.data.parcelDetailsByID[0].sector_)

          setEmployee(response.data.parcelDetailsByID[0].employee_name)
          //Assign Status
          setAssignStatus(response.data.parcelDetailsByID[0].assign_status)
          setRiderCode(response.data.parcelDetailsByID[0].assign_rider)
          if (
            response.data.parcelDetailsByID[0].service_name ===
            "Docs. Attestation"
          ) {
            setDocumentMode(true)
          } else {
            setDocumentMode(false)
          }
        } else {
          console.error("Failed to fetch users:", response.data.message)
        }
      })
      .catch(error => {
        console.error("Error fetching users:", error)
      })
  }

  const fetchParcelStatusByID = () => {
    const URL =
      `${apiURl}/fetchParcelStatusHistoryByID`
    const id = { id: CID }
    axios
      .post(URL, id)
      .then(response => {
        if (response.data.status === "success") {
          setParcelStatus(response.data.parcelStatusByID)
        } else {
          console.error("Failed to fetch users:", response.data.message)
        }
      })
      .catch(error => {
        console.error("Error fetching users:", error)
      })
  }

  useEffect(() => {
    fetchParcelinfoByID()
    fetchParcelStatusByID()
  }, [])

  const handleEdit = id => {
    navigate(`/edit_courier_detail/${id}`)
  }

  const EditStatus = () => {
    SetEdit(!Edit)
  }

  //Update Status
  const UpdateStatus = e => {
    e.preventDefault()
    if (changedstatus === "") {
      toastr.error("SET Status")
    } else if (changedstatus === "dispatch") {
      if (fromCity === null) {
        toastr.error("Set From City")
      } else if (ToCity === null) {
        toastr.error("Set To City")
      } else {
        executeAxiosRequest()
      }
    } else {
      executeAxiosRequest()
    }
  }

  const executeAxiosRequest = () => {
    const data = {
      update_id: CID,
      track_id: trackId,
      status: changedstatus,
      City: city,
      FCity: fromCity,
      TCity: ToCity,
    }
    const URL = `${apiURl}/changeStatus`
    axios
      .post(URL, data)
      .then(response => {
        if (response.data.status === "success") {
          toastr.success("Status is Updated")
          fetchParcelinfoByID()
          fetchParcelStatusByID()
          setLocationModal(false)
          if (Edit) {
            SetEdit(!Edit)
          } else if (EditAssign) {
            SetEditAssign(!EditAssign)
          }
        } else {
          toastr.error("Error in Update", response.data.message)
        }
      })
      .catch(error => console.log(error))
  }

  //Update Assign Status
  const UpdateAssignStatus = () => {
    const data = {
      update_id: CID,
      assignStatus: changeassignStatus,
    }
    const URL = `${apiURl}/changeAssignStatus`
    axios
      .post(URL, data)
      .then(response => {
        if (response.data.status === "success") {
          toastr.success("Assign Status is Updated")
          fetchParcelinfoByID()
          fetchParcelStatusByID()

          if (Edit) {
            SetEdit(!Edit)
          } else if (EditAssign) {
            SetEditAssign(!EditAssign)
          }
        } else {
          toastr.error("Error in Update", response.data.message)
        }
      })
      .catch(error => console.log(error))
  }

  //Fetch Riders
  const fetchRiders = () => {
    const data = {
      Role: role,
      User_id: login_user_id,
    }
    const URL = `${apiURl}/fetchRiders`
    axios
      .post(URL, data)
      .then(response => {
        if (response.data.status === "success") {
          const Riders = response.data.riders
          setRiders(Riders)
        } else {
          console.error("Failed to fetch riders:", response.data.message)
        }
      })
      .catch(error => {
        console.error("Error fetching riders:", error)
      })
  }

  const EditAssignStatus = () => {
    SetEditAssign(!EditAssign)
  }

  useEffect(() => {
    fetchRiders(role, login_user_id)
  }, [role, login_user_id])

  useEffect(() => {
    // Ensure Riders data is fetched before searching for a specific rider
    if (riders.length > 0) {
      const Rider = riders.find(rider => rider.user_id === RiderCode)
      // If a rider with the specified user ID is found, set it to changeAssignStatus
      if (Rider) {
        setChangeAssignStatus(Rider.user_id)
      } else {
        // Handle case when no rider with the specified user ID is found
        console.error("Rider with the specified user ID not found.")
      }
    }
  }, [riders, RiderCode])

  //
  const handleChangedStatus = event => {
    if (event.target.value === "dispatch") {
      setChangedstatus(event.target.value)
      setLocationModal(true)
    } else {
      setLocationModal(false)
      setChangedstatus(event.target.value)
    }
  }

  return (
    <React.Fragment>
      {" "}
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          {/* <Breadcrumbs title="Invoices" breadcrumbItem="Invoice Detail" /> */}

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="invoice-title">
                    {/* <div className="float-end">
                      <Button
                        className="btn btn-info btn-sm"
                        // onClick={() => handleEdit(CID)}
                      >
                        Edit
                      </Button>
                    </div> */}
                    <div className="mb-2">
                      <h4 className="font-size-16 mt-2">
                        Courier Order # {CID}
                      </h4>
                    </div>
                  </div>
                  <hr />

                  <Row>
                    <Col sm="6" className="mt-3">
                      <CardTitle style={{ color: "#556EE6" }}>
                        Supplier info
                      </CardTitle>
                      <br />
                      <div className="mb-2">
                        <address>
                          <strong>Name : </strong> {sname}
                        </address>
                      </div>
                      <div className="mb-2">
                        <address>
                          <strong> Phone : </strong>
                          {sphone}
                        </address>
                      </div>
                      <div className="mb-2">
                        <address>
                          <strong> Address </strong> <br /> {saddress}
                        </address>
                      </div>
                    </Col>
                    <Col sm="6" className="mt-3 text-sm">
                      <div className="float-end">
                        <CardTitle style={{ color: "#556EE6" }}>
                          Receiver info
                        </CardTitle>
                        <br />
                        <div className="mb-2">
                          <address>
                            <strong>Name : </strong> {rname}
                          </address>
                        </div>
                        <div className="mb-2">
                          <address>
                            <strong> Phone : </strong> {rphone}
                          </address>
                        </div>
                        <div className="mb-2">
                          <address>
                            <strong> Address </strong> <br />
                            {raddress}
                          </address>
                        </div>
                        <br />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="6">
                      <CardTitle style={{ color: "#556EE6" }}>Action</CardTitle>
                      <div className="mt-3 mb-2">
                        {!Edit && (
                          <div>
                            <address>
                              <strong>Assign Rider</strong>
                              <span>
                                <i
                                  className="bx bx-edit font-size-16"
                                  style={{ cursor: "pointer" }}
                                  onClick={EditAssignStatus}
                                ></i>
                              </span>{" "}
                              <br />
                              {EditAssign ? (
                                <Row>
                                  <Col xl="6">
                                    <div className="mb-2">
                                      <select
                                        defaultValue="0"
                                        className="form-select"
                                        onChange={e =>
                                          setChangeAssignStatus(e.target.value)
                                        }
                                        name="assign_rider"
                                        value={changeassignStatus}
                                      >
                                        <option value="">
                                          Select Rider ...
                                        </option>
                                        {riders.length > 0 ? (
                                          riders
                                            .filter(e => !!e)
                                            .map((e, index) => (
                                              <option
                                                key={index}
                                                value={e.user_id}
                                              >
                                                {e.full_name}
                                              </option>
                                            ))
                                        ) : (
                                          <option>No Data found</option>
                                        )}
                                      </select>
                                    </div>
                                  </Col>
                                </Row>
                              ) : (
                                (() => {
                                  switch (assignStatus) {
                                    case "NotAssign":
                                      return (
                                        <Badge
                                          className="bg-danger font-size-12"
                                          style={{ padding: "5px" }}
                                        >
                                          Not Assigned
                                        </Badge>
                                      )
                                    case "assigned":
                                      return (
                                        <Badge
                                          className="bg-success font-size-12"
                                          style={{ padding: "5px" }}
                                        >
                                          Assigned
                                        </Badge>
                                      )
                                    default:
                                      return "Unknown"
                                  }
                                })()
                              )}
                            </address>
                          </div>
                        )}
                      </div>
                      <div className="mt-3">
                        {!EditAssign && (
                          <div className="mb-2">
                            <address>
                              <strong> Change Status </strong>
                              <span>
                                <i
                                  className="bx bx-edit font-size-16"
                                  style={{ cursor: "pointer" }}
                                  onClick={EditStatus}
                                ></i>
                              </span>{" "}
                              <br />
                              {Edit ? (
                                <Row>
                                  <Col xl="6">
                                    <div className="mt-2">
                                      <select
                                        className="form-select"
                                        name="change_status"
                                        value={changedstatus}
                                        onChange={handleChangedStatus}
                                      >
                                        {(() => {
                                          switch (status) {
                                            case "new":
                                              return (
                                                <>
                                                  <option value={""}>
                                                    Select ...
                                                  </option>
                                                  <option value="in-progress">
                                                    In Progress
                                                  </option>
                                                  <option value="dispatch">
                                                    Dispatch
                                                  </option>
                                                  <option value="delivered">
                                                    Delivered
                                                  </option>
                                                  <option value="cancel">
                                                    Cancel
                                                  </option>
                                                </>
                                              )
                                            case "in-progress":
                                              return (
                                                <>
                                                  <option value={""}>
                                                    Select ...
                                                  </option>
                                                  <option value="dispatch">
                                                    Dispatch
                                                  </option>

                                                  <option value="delivered">
                                                    Delivered
                                                  </option>
                                                  <option value="cancel">
                                                    Cancel
                                                  </option>
                                                </>
                                              )
                                            case "dispatch":
                                              return (
                                                <>
                                                  <option value={""}>
                                                    Select ...
                                                  </option>
                                                  <option value="delivered">
                                                    Delivered
                                                  </option>
                                                  <option value="cancel">
                                                    Cancel
                                                  </option>
                                                </>
                                              )
                                            case "delivered":
                                              return (
                                                <>
                                                  <option value={""}>
                                                    Select ...
                                                  </option>
                                                  <option value="cancel">
                                                    Cancel
                                                  </option>
                                                </>
                                              )
                                            case "cancel":
                                              return (
                                                <>
                                                  <option value="">
                                                    Select ...{" "}
                                                  </option>
                                                  <option value="in-progress">
                                                    In Progress
                                                  </option>
                                                  <option value="dispatch">
                                                    Dispatch
                                                  </option>
                                                  <option value="delivered">
                                                    Delivered
                                                  </option>
                                                </>
                                              )
                                            default:
                                              return null
                                          }
                                        })()}
                                      </select>
                                    </div>
                                  </Col>
                                </Row>
                              ) : (
                                (() => {
                                  switch (status) {
                                    case "new":
                                      return (
                                        <Badge
                                          className="bg-info font-size-12"
                                          style={{ padding: "6px" }}
                                        >
                                          New
                                        </Badge>
                                      )
                                    case "cancel":
                                      return (
                                        <Badge
                                          className="bg-danger font-size-12"
                                          style={{ padding: "6px" }}
                                        >
                                          Cancel
                                        </Badge>
                                      )
                                    case "in-progress":
                                      return (
                                        <Badge
                                          className="bg-warning font-size-12"
                                          style={{ padding: "6px" }}
                                        >
                                          In Progress
                                        </Badge>
                                      )
                                    case "dispatch":
                                      return (
                                        <Badge
                                          className="bg-warning font-size-12"
                                          style={{ padding: "6px" }}
                                        >
                                          Dispatch
                                        </Badge>
                                      )
                                    case "delivered":
                                      return (
                                        <Badge
                                          className="bg-success font-size-12"
                                          style={{ padding: "6px" }}
                                        >
                                          Deliverd
                                        </Badge>
                                      )
                                  }
                                })()
                              )}
                            </address>
                          </div>
                        )}
                      </div>
                    </Col>
                    {!DocumentMode && (
                      <Col sm="6" className="mt-3 text-sm">
                        <div className="float-end">
                          <CardTitle style={{ color: "#556EE6" }}>
                            Service Detail
                          </CardTitle>
                          <br />
                          <div className="mb-2">
                            <address>
                              <strong>Services : </strong> {service}
                            </address>
                          </div>
                          <div className="mb-2">
                            <address>
                              <strong> Sub Service : </strong>
                              {(() => {
                                switch (subservice) {
                                  case "overnight":
                                    return "Over Night"
                                  case "flyer":
                                    return "Flyer"
                                  case "box":
                                    return "Box"
                                  default:
                                    break
                                }
                              })()}
                            </address>
                          </div>
                          <div className="mb-2">
                            <address>
                              <strong> Sector : </strong>
                              {(() => {
                                switch (sector) {
                                  case "wc":
                                    return "Within City"
                                  case "sz":
                                    return "Same Zone"
                                  case "dz":
                                    return "Differnt Zone"

                                  default:
                                    break
                                }
                              })()}
                            </address>
                          </div>
                          <br />
                        </div>
                      </Col>
                    )}
                    {DocumentMode && (
                      <Col sm="6" className="mt-3 text-sm">
                        <div className="float-end">
                          <div className="mb-2">
                            <address>
                              <strong>Services : </strong> {service}
                            </address>
                          </div>
                          <div className="mb-2">
                            <address>
                              <strong>Sub Services : </strong> {DocSubService}
                            </address>
                          </div>

                          <div>
                            <strong>Document Datail</strong>
                            <Input
                              type="textarea"
                              id="document_detail"
                              rows="2"
                              placeholder=""
                              name="document_detail"
                              value={documentDetail}
                              // onChange={handleParcel}
                              disabled
                            />
                          </div>
                        </div>
                      </Col>
                    )}
                  </Row>

                  {DocumentMode ? (
                    <>
                      <div className="py-2 mt-3">
                        <h3 className="font-size-15 fw-bold">
                          Documents summary
                        </h3>
                      </div>
                      <div className="table-responsive">
                        <Table
                          className="table-nowrap"
                          style={{ border: "1px solid #63719c" }}
                        >
                          <thead style={{ backgroundColor: "#F5F5F9" }}>
                            <tr>
                              <th>Track ID</th>
                              <th>Docs Deliver</th>
                              <th>Docs City Option</th>
                              <th>No. Of Docs</th>
                              <th>Charges(Rs)</th>
                              <th>CVI</th>

                              <th>CVI Amount</th>
                              <th>Assign Status</th>
                              <th>Courier Status</th>
                              <th>Booking Data</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Parcelinfo.length > 0 ? (
                              Parcelinfo.filter(e => !!e).map((e, index) => (
                                <tr key={index}>
                                  <td>{e.track_id}</td>
                                  <td>
                                    {(() => {
                                      switch (e.docs_office_type) {
                                        case "oto_":
                                          return " Office To Office"
                                        case "otd_":
                                          return "Office To Door"
                                        default:
                                          return "Unknown"
                                      }
                                    })()}
                                  </td>
                                  <td>
                                    {(() => {
                                      switch (e.docs_city_type) {
                                        case "wc_":
                                          return "Within City"
                                        case "ctc_":
                                          return "City To City"
                                        default:
                                          return "Unknown"
                                      }
                                    })()}
                                  </td>
                                  <td>{e.no_of_docs}</td>
                                  <td>
                                    {Number(e.docs_total_price).toFixed(2)}
                                  </td>
                                  <td>
                                    {(() => {
                                      switch (e.docs_cvi_value) {
                                        case "1":
                                          return "Done"
                                        case "0":
                                          return "None"
                                        default:
                                          return "Unknown"
                                      }
                                    })()}
                                  </td>

                                  <td>
                                    {parseFloat(e.doc_cvi_amount).toFixed(2)}
                                  </td>
                                  <td>
                                    {(() => {
                                      switch (e.assign_status) {
                                        case "NotAssign":
                                          return (
                                            <Badge className="bg-danger font-size-10">
                                              Not Assigned
                                            </Badge>
                                          )
                                        case "assigned":
                                          return (
                                            <Badge className="bg-success font-size-10">
                                              Assigned
                                            </Badge>
                                          )
                                        default:
                                          return "Unknown"
                                      }
                                    })()}
                                  </td>

                                  <td>
                                    {(() => {
                                      switch (e.status_) {
                                        case "new":
                                          return (
                                            <Badge className="bg-info font-size-12">
                                              New
                                            </Badge>
                                          )
                                        case "cancel":
                                          return (
                                            <Badge className="bg-danger font-size-12">
                                              Cancel
                                            </Badge>
                                          )
                                        case "in-progress":
                                          return (
                                            <Badge className="bg-warning font-size-12">
                                              In Progress
                                            </Badge>
                                          )
                                        case "dispatch":
                                          return (
                                            <Badge className="bg-warning font-size-12">
                                              Dispatch
                                            </Badge>
                                          )
                                        case "delivered":
                                          return (
                                            <Badge className="bg-success font-size-12">
                                              Deliverd
                                            </Badge>
                                          )
                                      }
                                    })()}
                                  </td>
                                  <td>{e.created_date}</td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td>No Data Found</td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="py-2 mt-3">
                        <h3 className="font-size-15 fw-bold">Parcel summary</h3>
                      </div>
                      <div className="table-responsive">
                        <Table
                          className="table-nowrap"
                          style={{ border: "1px solid #63719c" }}
                        >
                          <thead style={{ backgroundColor: "#F5F5F9" }}>
                            <tr>
                              <th>Track ID</th>
                              <th>No. Of Content</th>
                              <th>Content Weight</th>
                              <th>Content Value</th>
                              <th>CVI</th>
                              <th>Assign Status</th>
                              <th>Courier Status</th>
                              <th>Booking Data</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Parcelinfo.length > 0 ? (
                              Parcelinfo.filter(e => !!e).map((e, index) => (
                                <tr key={index}>
                                  <td>{e.track_id}</td>
                                  <td>{e.no_of_products}</td>
                                  <td>{e.parcel_weight} (Kg)</td>
                                  <td>
                                    {(() => {
                                      switch (e.parcel_price) {
                                        case "":
                                          return "0.00"
                                        case "0":
                                          return "0.00"
                                        default:
                                          return e.parcel_price
                                      }
                                    })()}
                                  </td>
                                  <td>
                                    {(() => {
                                      switch (e.parcel_cvi_value) {
                                        case "1":
                                          return "Done"
                                        case "0":
                                          return "None"
                                        default:
                                          return "Unknown"
                                      }
                                    })()}
                                  </td>
                                  <td>
                                    {(() => {
                                      switch (e.assign_status) {
                                        case "NotAssign":
                                          return (
                                            <Badge className="bg-danger font-size-10">
                                              Not Assigned
                                            </Badge>
                                          )
                                        case "assigned":
                                          return (
                                            <Badge className="bg-success font-size-10">
                                              Assigned
                                            </Badge>
                                          )
                                        default:
                                          return "Unknown"
                                      }
                                    })()}
                                  </td>
                                  <td>
                                    {(() => {
                                      switch (e.status_) {
                                        case "new":
                                          return (
                                            <Badge className="bg-info font-size-12">
                                              New
                                            </Badge>
                                          )
                                        case "in-progress":
                                          return (
                                            <Badge className="bg-warning font-size-12">
                                              In Progress
                                            </Badge>
                                          )
                                        case "dispatch":
                                          return (
                                            <Badge className="bg-warning font-size-12">
                                              Dispatch
                                            </Badge>
                                          )
                                        case "cancel":
                                          return (
                                            <Badge className="bg-danger font-size-12">
                                              Cancel
                                            </Badge>
                                          )
                                        case "delivered":
                                          return (
                                            <Badge className="bg-success font-size-12">
                                              Deliverd
                                            </Badge>
                                          )
                                      }
                                    })()}
                                  </td>
                                  <td>{e.created_date}</td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td>No Data Found</td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </div>
                    </>
                  )}

                  <div className="py-2 mt-3">
                    <h3 className="font-size-15 fw-bold">Status summary</h3>
                  </div>
                  <div className="table-responsive">
                    <Table
                      className="table-nowrap"
                      style={{ border: "1px solid #63719c" }}
                    >
                      <thead style={{ backgroundColor: "#F5F5F9" }}>
                        <tr>
                          <th>Track ID</th>
                          <th>All Status</th>
                          <th>Current City</th>
                          <th>Status Time</th>
                          <th>Status Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {ParcelStatus.length > 0 ? (
                          ParcelStatus.filter(e => !!e).map((e, index) => (
                            <tr key={index}>
                              <td>{e.track_id}</td>
                              <td>
                                {(() => {
                                  switch (e.parcel_status) {
                                    case "new":
                                      return (
                                        <Badge className="bg-info font-size-12">
                                          New
                                        </Badge>
                                      )
                                    case "cancel":
                                      return (
                                        <Badge className="bg-danger font-size-12">
                                          Cancel
                                        </Badge>
                                      )
                                    case "in-progress":
                                      return (
                                        <Badge className="bg-warning font-size-12">
                                          In Progress
                                        </Badge>
                                      )
                                    case "dispatch":
                                      return (
                                        <>
                                          {" "}
                                          <Badge className="bg-warning font-size-12">
                                            Dispatch {""}
                                          </Badge>
                                          (
                                          {`${e.from_location} -${e.to_location} `}
                                          )
                                        </>
                                      )
                                    case "delivered":
                                      return (
                                        <Badge className="bg-success font-size-12">
                                          Deliverd
                                        </Badge>
                                      )
                                  }
                                })()}
                              </td>
                              <td>{e.parcel_location} </td>
                              <td>{e.parcel_time} </td>
                              <td>{e.parcel_date} </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td>No Data Found</td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>

                  <div className="d-print-none">
                    {Edit && (
                      <div className="float-end">
                        <Button
                          color="primary"
                          className="btn btn-primary waves-effect waves-light gap-2"
                          onClick={UpdateStatus}
                        >
                          Save
                        </Button>
                      </div>
                    )}
                    {EditAssign && (
                      <div className="float-end">
                        <Button
                          color="primary"
                          className="btn btn-primary waves-effect waves-light gap-2"
                          onClick={UpdateAssignStatus}
                        >
                          Save
                        </Button>
                      </div>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <Modal
          isOpen={LocationModal}
          role="dialog"
          size="md"
          autoFocus={true}
          centered={true}
          className="exampleModal"
          tabIndex="-1"
          toggle={() => {
            setLocationModal(!LocationModal)
          }}
        >
          <div>
            <Form>
              <ModalHeader
                toggle={() => {
                  setLocationModal(!LocationModal)
                }}
              >
                Dispatch Detail
              </ModalHeader>
              <ModalBody>
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <label>From City *</label>
                      <Input
                        className="form-control"
                        type="text"
                        placeholder="From City"
                        name="user_name"
                        value={fromCity}
                        onChange={e => setFromCity(e.target.value)}
                      ></Input>
                    </div>
                    <div className="mb-3">
                      <label>To City *</label>
                      <Input
                        className="form-control"
                        type="text"
                        placeholder="To City"
                        name="user_city"
                        value={ToCity}
                        onChange={e => setToCity(e.target.value)}
                      ></Input>
                    </div>
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter>
                <Button
                  type="button"
                  color="secondary"
                  onClick={() => {
                    setLocationModal(!LocationModal)
                    setFromCity(null)
                    setToCity(null)
                  }}
                >
                  Close
                </Button>
                <Button color="primary" onClick={UpdateStatus}>
                  Submit
                </Button>
              </ModalFooter>
            </Form>
          </div>
        </Modal>

        <div id="print-data" style={{ display: "none" }}>
          <div className="text-center">Printed Data</div>
        </div>
      </div>
    </React.Fragment>
  )
}

CourierDetail.propTypes = {
  match: PropTypes.any,
}

export default withRouter(CourierDetail)
