import React, { useState, useEffect } from "react"
import { InputGroupAddon } from "reactstrap"

import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  CardTitle,
  Button,
  Label,
  Input,
  InputGroup,
  InputGroupText,
  FormFeedback,
  Form,
} from "reactstrap"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

//redux
import { useSelector, useDispatch } from "react-redux"
import withRouter from "components/Common/withRouter"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"

import avatar from "../../assets/images/users/avatar-1.jpg"
// actions
import { editProfile, resetProfileFlag } from "../../store/actions"
import axios from "axios"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons"

import toastr from "toastr"
import "toastr/build/toastr.min.css"
import { useNavigate } from "react-router-dom"

const UserProfile = () => {
  //meta title
  document.title = "Profile"
  const navigate = useNavigate()

  const [showPassword, setShowPassword] = useState(false)
  const [showFPassword, setShowFPassword] = useState(false)

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }
  const togglePasswordVisibility2 = () => {
    setShowFPassword(!showFPassword)
  }

  const [fullName, setFullName] = useState()
  const [email, setEmail] = useState()
  const [city, setCity] = useState()
  const [role, setRole] = useState()
  const [password, setPassword] = useState()
  const [Fpassword, setFpassword] = useState()

  const ID = localStorage.getItem("login_id")

  const fetchUserDataByID = id => {
    const URL = "https://api.ecsinternational.com.pk/fetchUserByID"
    axios
      .post(URL, { id })
      .then(response => {
        if (response.data.status === "success") {
          const UserDataByID = response.data.UserDataByID
          if (UserDataByID.length > 0) {
            setFullName(UserDataByID[0]?.full_name || "0")
            setEmail(UserDataByID[0]?.email_ || "0")
            setCity(UserDataByID[0]?.city_ || "0")
            setRole(UserDataByID[0]?.role_ || "0")
            setPassword(UserDataByID[0]?.password_ || "0")
            setFpassword(UserDataByID[0]?.password_ || "0")
          } else {
            console.error("No service data found")
          }
        } else {
          console.error("Failed to fetch service data:", response.data.message)
        }
      })
      .catch(error => {
        console.error("Error fetching service data:", error)
      })
  }

  useEffect(() => {
    fetchUserDataByID(ID)
  }, [ID])

  const handleEditUser = e => {
    e.preventDefault()

    if (!fullName) {
      toastr.error("Full Name is Required")
      return
    }
    if (!email) {
      toastr.error("Email is Required")
      return
    }
    if (!password || password !== Fpassword) {
      toastr.error("Passwords do not match")
      return
    }
    const userData = {
      edit_id: parseInt(ID),
      fullname: fullName,
      email: email,
      city: city,
      password: password,
      role: role,
    }
    const URL = "https://api.ecsinternational.com.pk/EditUser"
    axios
      .post(URL, userData)
      .then(response => {
        if (response.data.status === "success") {
          fetchUserDataByID(ID)
          toastr.success("Edit User successfully")
          navigate("/")
        } else {
          toastr.error(response.data.message || "Failed to Edit user")
        }
      })
      .catch(error => console.log(error))
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="" breadcrumbItem="Profile" />

          <Row>
            <Col xl={8}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Personal Detail</CardTitle>
                  <Form>
                    <Row className="mb-4">
                      <Label
                        htmlFor="horizontal-firstname-Input"
                        className="col-sm-3 col-form-label"
                      >
                        First name
                      </Label>
                      <Col sm={9}>
                        <Input
                          type="text"
                          className="form-control"
                          id="horizontal-firstname-Input"
                          placeholder="Enter Your"
                          value={fullName}
                          onChange={e => setFullName(e.target.value)}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-4">
                      <Label
                        htmlFor="horizontal-email-Input"
                        className="col-sm-3 col-form-label"
                      >
                        Email
                      </Label>
                      <Col sm={9}>
                        <Input
                          type="email"
                          className="form-control"
                          id="horizontal-email-Input"
                          placeholder="Enter Your Email ID"
                          value={email}
                          onChange={e => setEmail(e.target.value)}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-4">
                      <Label
                        htmlFor="horizontal-password-Input"
                        className="col-sm-3 col-form-label"
                      >
                        Password
                      </Label>
                      <Col sm={9}>
                        <InputGroup>
                          <Input
                            type={showPassword ? "text" : "password"}
                            className="form-control"
                            id="horizontal-password-Input"
                            placeholder="Enter Your Password"
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                          />
                          <InputGroupText
                            onClick={togglePasswordVisibility}
                            style={{ cursor: "pointer" }}
                          >
                            <FontAwesomeIcon
                              icon={showPassword ? faEyeSlash : faEye}
                            />
                          </InputGroupText>
                        </InputGroup>
                      </Col>
                    </Row>
                    <Row className="mb-4">
                      <Label
                        htmlFor="horizontal-password-Input2"
                        className="col-sm-3 col-form-label"
                      >
                        Confirm Password
                      </Label>
                      <Col sm={9}>
                        <InputGroup>
                          <Input
                            type={showFPassword ? "text" : "password"}
                            className="form-control"
                            id="horizontal-password-Input2"
                            placeholder="Enter Your Password"
                            value={Fpassword}
                            onChange={e => setFpassword(e.target.value)}
                          />
                          <InputGroupText
                            onClick={togglePasswordVisibility2}
                            style={{ cursor: "pointer" }}
                          >
                            <FontAwesomeIcon
                              icon={showFPassword ? faEyeSlash : faEye}
                            />
                          </InputGroupText>
                        </InputGroup>
                      </Col>
                    </Row>

                    <Row className="justify-content-end">
                      <Col sm={9}>
                        <div>
                          <Button
                            type="submit"
                            color="primary"
                            className="w-md"
                            onClick={handleEditUser}
                          >
                            Submit
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(UserProfile)
