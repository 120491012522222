import React, { useEffect, useState, useMemo } from "react"
import PropTypes from "prop-types"
import { Link, useNavigate } from "react-router-dom"

import {
  Button,
  Col,
  Container,
  Collapse,
  Input,
  Row,
  Label,
  Card,
  CardBody,
  CardTitle,
} from "reactstrap"
//Date Picker
import "react-datepicker/dist/react-datepicker.css"
import "assets/scss/datatables.scss"
import classnames from "classnames"

import toastr from "toastr"
import "toastr/build/toastr.min.css"

// import { cryptoOrderData } from "../../../common/data";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import axios from "axios"
import { use } from "i18next"
import Select from "react-select"
import apiURl from "url"

const EcsControls = props => {
  //meta title
  document.title = "Setting"
  const navigate = useNavigate()
  const role_ = localStorage.getItem("role")
  if (role_ !== "admin") {
    navigate("/")
  }

  const [col1, setcol1] = useState(false)
  const [col2, setcol2] = useState(false)
  const [col3, setcol3] = useState(false)
  const [col4, setcol4] = useState(false)

  const t_col1 = () => {
    setcol1(!col1)
    setcol2(false)
    setcol3(false)
    setcol4(false)
  }
  const t_col2 = () => {
    setcol2(!col2)
    setcol1(false)
    setcol3(false)
    setcol4(false)
  }
  const t_col3 = () => {
    setcol3(!col3)
    setcol1(false)
    setcol2(false)
    setcol4(false)
  }
  const t_col4 = () => {
    setcol4(!col4)
    setcol1(false)
    setcol2(false)
    setcol3(false)
  }

  //Service Data
  const [services_name, setServices_name] = useState("")
  const [S_price, setS_price] = useState("")
  const [pr_per_km, setPr_per_km] = useState("")
  const [pr_per_kg, setPr_per_kg] = useState("")
  //Service option
  const [soption, setSoption] = useState("")
  const [sname, setSname] = useState("")
  //Employee Data
  const [Pmethod, setPmethod] = useState("")
  //CVI data
  const [cviprice, setCviprice] = useState()
  const [cvipercent, setCvipercent] = useState()
  //Documents
  const [Docamount, setDocamount] = useState({
    oto_wc_3docs: "",
    oto_wc_addocs: "",
    oto_ctc_3docs: "",
    oto_ctc_adddoc: "",

    otd_wc_3docs: "",
    otd_wc_addocs: "",
    otd_ctc_3docs: "",
    otd_ctc_adddoc: "",
  })
  const handleDocAmount = e => {
    const { name, value } = e.target
    setDocamount(prevData => ({
      ...prevData,
      [name]: value,
    }))
  }
  const handleDoucumentSubm = async () => {
    if (Docamount.oto_wc_3docs.length === 0) {
      toastr.error("Set All Prices")
    } else if (Docamount.oto_wc_addocs.length === 0) {
      toastr.error("Set All Prices")
    } else if (Docamount.oto_ctc_3docs.length === 0) {
      toastr.error("Set All Prices")
    } else if (Docamount.oto_ctc_adddoc.length === 0) {
      toastr.error("Set All Prices")
    } else if (Docamount.otd_wc_3docs.length === 0) {
      toastr.error("Set All Prices")
    } else if (Docamount.otd_wc_addocs.length === 0) {
      toastr.error("Set All Prices")
    } else if (Docamount.otd_ctc_3docs.length === 0) {
      toastr.error("Set All Prices")
    } else if (Docamount.otd_ctc_adddoc.length === 0) {
      toastr.error("Set All Prices")
    }
    try {
      const response = await axios.post(
        `${apiURl}/AddDocsServiceData`,
        Docamount
      )
      if (response.data.success) {
        // Handle success
        toastr.success("Added successfully")
        setDocamount({
          oto_wc_3docs: "",
          oto_wc_addocs: "",
          oto_ctc_3docs: "",
          oto_ctc_adddoc: "",

          otd_wc_3docs: "",
          otd_wc_addocs: "",
          otd_ctc_3docs: "",
          otd_ctc_adddoc: "",
        })
        setcol4(!col4)
      } else {
        // Handle failure
        console.error("Failed to add:", response.data.message)
      }
    } catch (error) {
      // Handle error
      console.error("Error adding:", error)
    }
  }

  //Handle Service
  const handleServices = () => {
    const URL = `${apiURl}/AddServices`
    const data = {
      service_name: services_name,
      service_price: S_price,
      price_per_km: pr_per_km,
      price_per_kg: pr_per_kg,
    }
    if (services_name === "") {
      toastr.error("Service name is Required")
    } else {
      axios
        .post(URL, data)
        .then(response => {
          if (response.data.success) {
            toastr.success("Add Successfully")
            setServices_name("")
            setS_price("")
            setPr_per_kg("")
            setPr_per_km("")
            setcol1(!col1)
          } else {
            toastr.error("Error in Adding")
          }
        })

        .catch(error => console.log(error))
    }
  }

  //Hanlde Employee
  const handelEmployee = () => {
    const URL = `${apiURl}/AddPaymentMethod`
    const data = {
      payment_method: Pmethod,
    }
    if (Pmethod === "") {
      toastr.error("Method is Required")
    } else {
      axios
        .post(URL, data)
        .then(response => {
          if (response.data.success) {
            setPmethod("")
            toastr.success("Add Successfully")
            setcol2(!col2)
          } else {
            toastr.error("Error in Adding")
          }
        })

        .catch(error => console.log(error))
    }
  }

  //Fetch Service
  const fetchService = () => {
    const URL = `${apiURl}/fetchServiceData`
    axios
      .get(URL)
      .then(response => {
        if (response.data.status === "success") {
          const serviceData = response.data.serviceData
          if (serviceData.length > 0) {
            const options = response.data.serviceData.map(row => ({
              label: row.service_name,
              value: row.service_name,
            }))
            setSoption(options)
          } else {
            console.error("No service data found")
          }
        } else {
          console.error("Failed to fetch service data:", response.data.message)
        }
      })
      .catch(error => {
        console.error("Error fetching service data:", error)
      })
  }

  //onChangeSErvice
  const onChangeservice = select => {
    setSname(select)
  }
  const handleCVI = () => {
    const URL = `${apiURl}/AddCVIdata`
    const data = {
      service_cat: sname.value,
      cvi_percent: cvipercent,
      cvi_amount: cviprice,
    }
    if (sname.value === "") {
      toastr.error("Select Service")
    } else if (cviprice === "") {
      toastr.error("Set CVI price")
    } else if (cvipercent === "") {
      toastr.error("Set CVI percent")
    } else {
      axios
        .post(URL, data)
        .then(response => {
          if (response.data.success) {
            setSname("")
            setCviprice("")
            setCvipercent("")
            toastr.success("Add Successfully")
            setcol3(!col3)
          } else {
            toastr.error("Error in Adding")
          }
        })

        .catch(error => console.log(error))
    }
  }

  useEffect(() => {
    fetchService()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          {/* <Breadcrumbs title="Crypto" breadcrumbItem="Orders" /> */}
          <Row>
            <Col xl="10">
              <div>
                <h5 className="mt-2">Controls</h5>
              </div>
            </Col>
            {/* <Col xl="2">
              <div className="float-end">
                <Button className="btn-sm btn btn-success">Expand All</Button>
              </div>
            </Col> */}
          </Row>

          <Row className="pt-3">
            <Col lg="6">
              <div className="accordion" id="accordion">
                {/* <div className="accordion-item mb-3">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className={classnames("accordion-button", "fw-medium", {
                        collapsed: !col1,
                      })}
                      type="button"
                      onClick={t_col1}
                      style={{ cursor: "pointer", backgroundColor: "white" }}
                    >
                      Add Services Detail
                    </button>
                  </h2>

                  <Collapse isOpen={col1} className="accordion-collapse">
                    <div className="accordion-body">
                      <Row>
                        <Col xl={12}>
                          <Row>
                            <Col xl={8}>
                              <div className="mb-3">
                                <label>Services Name</label>
                                <Input
                                  className="form-control"
                                  type="text"
                                  placeholder="service name"
                                  name="payment_mode"
                                  value={services_name}
                                  onChange={e =>
                                    setServices_name(e.target.value)
                                  }
                                ></Input>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col xl={8}>
                              <div className="mb-3">
                                <label>Service Price</label>
                                <Input
                                  className="form-control"
                                  type="number"
                                  placeholder="services price"
                                  name="services price"
                                  value={S_price}
                                  onChange={e => setS_price(e.target.value)}
                                ></Input>
                              </div>
                            </Col>
                            <Col xl={6}>
                              <div className="mb-3">
                                <label>Price (per km) </label>
                                <Input
                                  className="form-control"
                                  type="number"
                                  placeholder="price per km"
                                  name="payment_mode"
                                  value={pr_per_km}
                                  onChange={e => setPr_per_km(e.target.value)}
                                ></Input>
                              </div>
                            </Col>
                            <Col xl={6}>
                              <div></div>
                              <div className="mb-3">
                                <label>Price (per kg)</label>
                                <Input
                                  className="form-control"
                                  type="number"
                                  placeholder="price per kg"
                                  name="payment_mode"
                                  value={pr_per_kg}
                                  onChange={e => setPr_per_kg(e.target.value)}
                                ></Input>
                              </div>
                            </Col>
                          </Row>
                          <div className="mt-2">
                            <label></label>
                            <Button
                              color="primary"
                              onClick={handleServices}
                              className="btn btn-sm"
                            >
                              Submit
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Collapse>
                </div> */}
                <div className="accordion-item mb-3">
                  <h2 className="accordion-header" id="headingTwo">
                    <button
                      className={classnames("accordion-button", "fw-medium", {
                        collapsed: !col2,
                      })}
                      type="button"
                      onClick={t_col2}
                      style={{ cursor: "pointer", backgroundColor: "white" }}
                    >
                      Payment Method
                    </button>
                  </h2>

                  <Collapse isOpen={col2} className="accordion-collapse">
                    <div className="accordion-body">
                      <Row>
                        <Col xl={12}>
                          <Row>
                            <Col xl={8}>
                              <div className="mb-3">
                                <label>Payment Method</label>
                                <Input
                                  className="form-control"
                                  type="text"
                                  placeholder="payment method"
                                  name="payment_method"
                                  value={Pmethod}
                                  onChange={e => setPmethod(e.target.value)}
                                ></Input>
                              </div>
                            </Col>
                          </Row>
                          <div className="mt-2">
                            <label></label>
                            <Button
                              color="primary"
                              onClick={handelEmployee}
                              className="btn btn-sm"
                            >
                              Submit
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Collapse>
                </div>
              </div>
            </Col>
            {/* <Col lg="6">
              <div className="accordion" id="accordion">
                <div className="accordion-item mb-3">
                  <h2 className="accordion-header" id="headingThree">
                    <button
                      className={classnames("accordion-button", "fw-medium", {
                        collapsed: !col3,
                      })}
                      type="button"
                      onClick={t_col3}
                      style={{ cursor: "pointer", backgroundColor: "white" }}
                    >
                      Define CVI
                    </button>
                  </h2>

                  <Collapse isOpen={col3} className="accordion-collapse">
                    <Card>
                      <CardBody>
                        <Row>
                          <Col xl={12}>
                            <Row>
                              <Col xl={8}>
                                <div className="mb-3">
                                  <Label>For Service</Label>
                                  <Select
                                    value={sname}
                                    onChange={onChangeservice}
                                    name="service_option"
                                    options={soption}
                                    styles={{ backgroundColor: "white" }}
                                    className="select2-selection"
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl={6}>
                                <div className="mb-3">
                                  <label>Define Price</label>
                                  <Input
                                    className="form-control"
                                    type="text"
                                    placeholder="price"
                                    name="cvi_price"
                                    value={cviprice}
                                    onChange={e => setCviprice(e.target.value)}
                                  ></Input>
                                </div>
                              </Col>
                              <Col xl={6}>
                                <div className="mb-3">
                                  <label>Define %</label>
                                  <Input
                                    className="form-control"
                                    type="number"
                                    placeholder="%"
                                    name="cvi_percent"
                                    value={cvipercent}
                                    onChange={e =>
                                      setCvipercent(e.target.value)
                                    }
                                  ></Input>
                                </div>
                              </Col>
                            </Row>
                            <div className="mt-2">
                              <label></label>
                              <Button
                                color="primary"
                                onClick={handleCVI}
                                className="btn btn-sm"
                              >
                                Submit
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Collapse>
                </div>
                <div className="accordion-item mb-3">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className={classnames("accordion-button", "fw-medium", {
                        collapsed: !col4,
                      })}
                      type="button"
                      onClick={t_col4}
                      style={{ cursor: "pointer", backgroundColor: "white" }}
                    >
                      Documents Service Detail
                    </button>
                  </h2>

                  <Collapse isOpen={col4} className="accordion-collapse">
                    <div className="accordion-body">
                      <Row>
                        <Col xl={12}>
                          <Row>
                            <CardTitle className="mb-3">
                              {" "}
                              Office To Office Collection
                            </CardTitle>
                            <h6 style={{ color: "blue" }} className="mb-3">
                              (Within City)
                            </h6>
                            <Col xl={6}>
                              <div className="mb-3">
                                <label>Up to 3 Docs</label>
                                <Input
                                  className="form-control"
                                  type="number"
                                  placeholder="Rs"
                                  name="oto_wc_3docs"
                                  value={Docamount.oto_wc_3docs}
                                  onChange={handleDocAmount}
                                ></Input>
                              </div>
                            </Col>
                            <Col xl={6}>
                              <div className="mb-3">
                                <label>Each Addl. Docs</label>
                                <Input
                                  className="form-control"
                                  type="number"
                                  placeholder="Rs"
                                  name="oto_wc_addocs"
                                  value={Docamount.oto_wc_addocs}
                                  onChange={handleDocAmount}
                                ></Input>
                              </div>
                            </Col>
                            <h6 style={{ color: "blue" }} className="mb-3">
                              (City to City)
                            </h6>
                            <Col xl={6}>
                              <div className="mb-3">
                                <label>Up to 3 Docs</label>
                                <Input
                                  className="form-control"
                                  type="number"
                                  placeholder="Rs"
                                  name="oto_ctc_3docs"
                                  value={Docamount.oto_ctc_3docs}
                                  onChange={handleDocAmount}
                                ></Input>
                              </div>
                            </Col>
                            <Col xl={6}>
                              <div className="mb-3">
                                <label>Each Addl. Docs</label>
                                <Input
                                  className="form-control"
                                  type="number"
                                  placeholder="Rs"
                                  name="oto_ctc_adddoc"
                                  value={Docamount.oto_ctc_adddoc}
                                  onChange={handleDocAmount}
                                ></Input>
                              </div>
                            </Col>
                          </Row>
                          <br />
                          <div
                            style={{
                              borderTop: "1px solid #3E4559",
                              width: "100%",
                            }}
                          ></div>
                          <br />
                          <Row>
                            <CardTitle className="mb-3">
                              {" "}
                              Office To Door Deliver
                            </CardTitle>
                            <h6 style={{ color: "blue" }} className="mb-3">
                              (Within City)
                            </h6>
                            <Col xl={6}>
                              <div className="mb-3">
                                <label>Up to 3 Docs</label>
                                <Input
                                  className="form-control"
                                  type="number"
                                  placeholder="Rs"
                                  name="otd_wc_3docs"
                                  value={Docamount.otd_wc_3docs}
                                  onChange={handleDocAmount}
                                ></Input>
                              </div>
                            </Col>
                            <Col xl={6}>
                              <div className="mb-3">
                                <label>Each Addl. Docs</label>
                                <Input
                                  className="form-control"
                                  type="number"
                                  placeholder="Rs"
                                  name="otd_wc_addocs"
                                  value={Docamount.otd_wc_addocs}
                                  onChange={handleDocAmount}
                                ></Input>
                              </div>
                            </Col>
                            <h6 style={{ color: "blue" }} className="mb-3">
                              (City to City)
                            </h6>
                            <Col xl={6}>
                              <div className="mb-3">
                                <label>Up to 3 Docs</label>
                                <Input
                                  className="form-control"
                                  type="number"
                                  placeholder="Rs"
                                  name="otd_ctc_3docs"
                                  value={Docamount.otd_ctc_3docs}
                                  onChange={handleDocAmount}
                                ></Input>
                              </div>
                            </Col>
                            <Col xl={6}>
                              <div className="mb-3">
                                <label>Each Addl. Docs</label>
                                <Input
                                  className="form-control"
                                  type="number"
                                  placeholder="Rs"
                                  name="otd_ctc_adddoc"
                                  value={Docamount.otd_ctc_adddoc}
                                  onChange={handleDocAmount}
                                ></Input>
                              </div>
                            </Col>
                          </Row>
                          <div className="mt-2">
                            <label></label>
                            <Button
                              color="primary"
                              onClick={handleDoucumentSubm}
                              className="btn btn-sm"
                            >
                              Submit
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Collapse>
                </div>
              </div>
            </Col> */}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EcsControls
