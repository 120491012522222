import React from "react"
import pdfMake from "pdfmake/build/pdfmake"
import pdfFonts from "pdfmake/build/vfs_fonts"
import { UncontrolledTooltip } from "reactstrap"
import pdfIcon from "../../../src/assets/images/pdf.svg"

pdfMake.vfs = pdfFonts.pdfMake.vfs

const PdfLeadsDownload = ({ leads }) => {
  const moment = require("moment-timezone")

  const downloadPdf = () => {
    const currentDate = moment().tz("Asia/Karachi").format("YYYY-MM-DD")

    const headers = Object.keys(leads[0]).filter(header => header !== "id")
    const data = leads.map(lead => headers.map(header => lead[header]))

    const docDefinition = {
      content: [
        { text: `Leads Report (${currentDate})`, style: "header" },
        {
          table: {
            headerRows: 1,
            widths: headers.map(() => "*"),
            body: [headers, ...data],
          },
          layout: "lightHorizontalLines",
        },
      ],
      styles: {
        header: {
          fontSize: 18,
          bold: true,
          margin: [0, 0, 0, 10],
          color: "blue",
        },
        tableHeader: {
          fillColor: "yellow",
          bold: true,
        },
      },
    }

    pdfMake.createPdf(docDefinition).download(`${currentDate}-Leads.pdf`)
  }

  return (
    <div>
      <img
        src={pdfIcon}
        alt="pdf.svg"
        onClick={downloadPdf}
        style={{
          cursor: "pointer",
          border: "1px solid #bfc1c5",
          borderRadius: "5px",
          padding: "5px",
        }}
        id="pdfIcon"
      />
      <UncontrolledTooltip placement="top" target="pdfIcon">
        PDF
      </UncontrolledTooltip>
    </div>
  )
}

export default PdfLeadsDownload
