import React, { useEffect, useState, useMemo } from "react"
import PropTypes from "prop-types"
import { Link, useNavigate, Redirect } from "react-router-dom"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons"

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Form,
  InputGroup,
  InputGroupText,
  FormGroup,
  Label,
  UncontrolledTooltip,
  Modal,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import classnames from "classnames"
import { connect } from "react-redux"
import withRouter from "components/Common/withRouter"

import {
  Pdate,
  Type,
  Value,
  ValueinUSD,
  Status,
  Coin,
  Tracked,
} from "../EcsUsers/userstabledata"

import TableContainer from "../../components/Common/TableContainer"

// import img2 from "../../assets/images/no-time-off.59f54e64.svg"

//Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "assets/scss/datatables.scss"

// import { cryptoOrderData } from "../../../common/data";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import { getCryptoOrders } from "store/crypto/actions"

import toastr from "toastr"
import "toastr/build/toastr.min.css"
import { post } from "helpers/api_helper"
import axios from "axios"
import DeleteModal from "components/Common/DeleteModal"
import apiURl from "url"

const AllRequestsDetail = props => {
  //meta title
  document.title = "Ecs Users"
  const navigate = useNavigate()

  const role_ = localStorage.getItem("role")
  if (role_ !== "admin") {
    navigate("/")
  }

  const [showPassword, setShowPassword] = useState(false)
  const [showFPassword, setShowFPassword] = useState(false)

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }
  const togglePasswordVisibility2 = () => {
    setShowFPassword(!showFPassword)
  }

  const [orders, setOrders] = useState([])

  
  const fetchUsers = () => {
    setOrders([])
    const URL = `${apiURl}/fetchUsers`
    axios
      .get(URL)
      .then(response => {
        if (response.data.status === "success") {
          setOrders(response.data.users)
        } else {
          console.error("Failed to fetch users:", response.data.message)
        }
      })
      .catch(error => {
        console.error("Error fetching users:", error)
      })
  }

  useEffect(() => {
    fetchUsers()
  }, [])


  // Table Data

  const columns = useMemo(
    () => [
      {
        Header: "Code",
        accessor: "user_id",
        disableFilters: true,
        Cell: cellProps => {
          return <>{cellProps.value}</>
        },
      },
      {
        Header: "Full Name",
        accessor: "full_name",
        disableFilters: true,
        Cell: cellProps => {
          return <>{cellProps.value}</>
        },
      },
      {
        Header: "Email",
        accessor: "email_",
        disableFilters: true,
        Cell: cellProps => {
          return <Type {...cellProps} />
        },
      },
      {
        Header: "City",
        accessor: "city_",
        disableFilters: true,
        Cell: cellProps => {
          return <Type {...cellProps} />
        },
      },
      {
        Header: "Role",
        accessor: "role_",
        disableFilters: true,
        Cell: cellProps => {
          return <Coin {...cellProps} />
        },
      },
      {
        Header: "Action",
        accessor: "id",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <>
              <div className="d-flex gap-3">
                <Link
                  to="#"
                  className="text-danger"
                  onClick={() => deleteUser(cellProps.value)}
                >
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                  />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </Link>
              </div>
            </>
          )
        },
      },
    ],
    []
  )

  //Delete User
  const [deleteModal, setDeleteModal] = useState(false)
  const [del_id, setDel_id] = useState([])
  const deleteUser = id => {
    setDeleteModal(true)
    setDel_id(id)
  }
  const handleDeleteUser = () => {
    const URL = `${apiURl}/deleteUser`
    axios
    .post(URL, { id: del_id })
    .then(response => {
      if (response.data.status === "success") {
        setDeleteModal(false)
        toastr.success("User Delete successfully")
        fetchUsers()
      } else {
        setDeleteModal(false)
        console.error("Failed to Delete users:", response.data.message)
      }
    })
    .catch(error => {
      setDeleteModal(false);
      console.error("Error deleting user:", error);
    });
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          {/* <Breadcrumbs title="Crypto" breadcrumbItem="Orders" /> */}
          <Row>
            <Col xl="6">
              <div>
                <h5 className="mt-2">All Requests</h5>
              </div>
            </Col>
          </Row>

          <Row className="pt-3">
            <Col lg="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={orders}
                    // isGlobalFilter={true}
                    // isAddOptions={false}
                    customPageSize={10}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      
    </React.Fragment>
  )
}

export default AllRequestsDetail
