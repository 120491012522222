import React, { useEffect, useState, useMemo } from "react"
import PropTypes from "prop-types"
import { Link, useNavigate } from "react-router-dom"

import {
  Button,
  Col,
  Container,
  Collapse,
  Input,
  Row,
  Label,
  Card,
  CardBody,
  CardTitle,
} from "reactstrap"
//Date Picker
import "react-datepicker/dist/react-datepicker.css"
import "assets/scss/datatables.scss"
import classnames from "classnames"

import toastr from "toastr"
import "toastr/build/toastr.min.css"

// import { cryptoOrderData } from "../../../common/data";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import axios from "axios"
import { use } from "i18next"
import Select from "react-select"
import apiURl from "url"

const AddServicesCharges = props => {
  //meta title
  document.title = "Setting"
  const navigate = useNavigate()
  const role_ = localStorage.getItem("role")
  if (role_ !== "admin") {
    navigate("/")
  }

  //Service Data
  const [mainService, setMainService] = useState("")
  const [subService, setSubService] = useState("")
  const [serv_sector, setServ_sector] = useState("")
  const [serv_cahrges, setServ_charges] = useState("")
  const [parcel_weight, setParcelWeight] = useState("")
  const [extra_w_chrg, setExtra_w_chrg] = useState("")

  //Handle Service
  const handleServices = () => {
    const URL = `${apiURl}/AddServiceCharges`
    const data = {
      mainService: mainService,
      subService: subService,
      sector: serv_sector,
      serv_cahrges: serv_cahrges,
      parcel_weight: parcel_weight,
      extra_w_chrg: extra_w_chrg,
    }

    axios
      .post(URL, data)
      .then(response => {
        if (response.data.success) {
          toastr.success("Add Successfully")
        } else {
          toastr.error("Error in Adding")
        }
      })

      .catch(error => console.log(error))
  }

  //Fetch Service
  const fetchService = () => {
    const URL = `${apiURl}/fetchServiceData`
    axios
      .get(URL)
      .then(response => {
        if (response.data.status === "success") {
          const serviceData = response.data.serviceData
          if (serviceData.length > 0) {
            const options = response.data.serviceData.map(row => ({
              label: row.service_name,
              value: row.service_name,
            }))
            setSoption(options)
          } else {
            console.error("No service data found")
          }
        } else {
          console.error("Failed to fetch service data:", response.data.message)
        }
      })
      .catch(error => {
        console.error("Error fetching service data:", error)
      })
  }

  useEffect(() => {
    fetchService()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          {/* <Breadcrumbs title="Crypto" breadcrumbItem="Orders" /> */}
          <Row>
            <Col xl="10">
              <div>
                <h5 className="mt-2">Add Services Charges Category</h5>
              </div>
            </Col>
          </Row>

          <Card>
            <CardBody>
              <Row>
                <Col xl={12}>
                  <Row>
                    <Col xl={3}>
                      <div className="mb-3">
                        <label>Main Services</label>
                        <Input
                          className="form-control"
                          type="text"
                          value={mainService}
                          onChange={e => setMainService(e.target.value)}
                        ></Input>
                      </div>
                    </Col>
                    <Col xl={3}>
                      <div className="mb-3">
                        <label>Sub Services</label>
                        <Input
                          className="form-control"
                          type="text"
                          value={subService}
                          onChange={e => setSubService(e.target.value)}
                        ></Input>
                      </div>
                    </Col>
                    <Col xl={3}>
                      <div className="mb-3">
                        <label>Services Sector</label>
                        <Input
                          className="form-control"
                          type="text"
                          value={serv_sector}
                          onChange={e => setServ_sector(e.target.value)}
                        ></Input>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={3}>
                      <div className="mb-3">
                        <label>Service Charges</label>
                        <Input
                          className="form-control"
                          type="number"
                          value={serv_cahrges}
                          onChange={e => setServ_charges(e.target.value)}
                        ></Input>
                      </div>
                    </Col>
                    <Col xl={3}>
                      <div className="mb-3">
                        <label>Parcel Weight</label>
                        <Input
                          className="form-control"
                          type="number"
                          name="payment_mode"
                          value={parcel_weight}
                          onChange={e => setParcelWeight(e.target.value)}
                        ></Input>
                      </div>
                    </Col>
                    <Col xl={3}>
                      <div></div>
                      <div className="mb-3">
                        <label>Extra weight Cahrges</label>
                        <Input
                          className="form-control"
                          type="number"
                          name="payment_mode"
                          value={extra_w_chrg}
                          onChange={e => setExtra_w_chrg(e.target.value)}
                        ></Input>
                      </div>
                    </Col>
                  </Row>
                  <div className="mt-2">
                    <label></label>
                    <Button
                      color="primary"
                      onClick={handleServices}
                      className="btn btn-sm"
                    >
                      Submit
                    </Button>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddServicesCharges
