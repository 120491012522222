import React, { useEffect, useState, useMemo } from "react"
import PropTypes from "prop-types"
import { Link, useNavigate } from "react-router-dom"

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  UncontrolledTooltip,
  Modal,
  Nav,
  NavItem,
  Badge,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import classnames from "classnames"
import { connect } from "react-redux"
import withRouter from "components/Common/withRouter"

import {
  Pdate,
  Type,
  Value,
  ValueinUSD,
  Status,
  Coin,
  Tracked,
} from "../EcsUsers/userstabledata"

import TableContainer from "../../components/Common/TableContainer"

// import img2 from "../../assets/images/no-time-off.59f54e64.svg"

//Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "assets/scss/datatables.scss"

// import { cryptoOrderData } from "../../../common/data";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import { getCryptoOrders } from "store/crypto/actions"

import toastr from "toastr"
import "toastr/build/toastr.min.css"
import { post } from "helpers/api_helper"
import axios from "axios"
import apiURl from "url"

const AllEmployeeDetail = props => {
  //meta title
  document.title = "Ecs Users"
  const navigate = useNavigate()

  const [orders, setOrders] = useState([])

  const fetchEmployee = () => {
    const URL = `${apiURl}/fetchEmployeeData`
    axios
      .get(URL)
      .then(response => {
        if (response.data.status === "success") {
          const employeeData = response.data.employeeData
          if (employeeData.length > 0) {
            setOrders(employeeData)
          } else {
            console.error("No service data found")
          }
        } else {
          console.error("Failed to fetch Employee:", response.data.message)
        }
      })
      .catch(error => {
        console.error("Error fetching Employee:", error)
      })
  }

  useEffect(() => {
    fetchEmployee()
  }, [])

  const deleteEmployee = id => {
    const del_id = id
    const URL = `${apiURl}/deleteEmployee`
    axios
      .post(URL, { id: del_id })
      .then(response => {
        if (response.data.status === "success") {
          fetchUsers()
          toastr.success("Employee Delete successfully")
        } else {
          console.error("Failed to Delete Employee:", response.data.message)
        }
      })
      .catch(error => console.log(error))
  }
  // Table Data

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        disableFilters: true,
        Cell: cellProps => {
          return <Type {...cellProps} />
        },
      },
      {
        Header: "Employee Name",
        accessor: "em_name",
        disableFilters: true,
        Cell: cellProps => {
          return <Type {...cellProps} />
        },
      },

      {
        Header: "Employee ID",
        accessor: "em_id",
        disableFilters: true,
        Cell: cellProps => {
          return <Type {...cellProps} />
        },
      },

      {
        Header: "Action",
        accessor: "id2",
        disableFilters: true,
        Cell: cellProps => {
          const id = cellProps.row.original.id
          return (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                    onClick={() => deleteEmployee(id)}
                  />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </Link>
              </div>
            </>
          )
        },
      },
    ],
    []
  )

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          {/* <Breadcrumbs title="Crypto" breadcrumbItem="Orders" /> */}
          <Row>
            <Col xl="6">
              <div>
                <h5 className="mt-2">All Employee</h5>
              </div>
            </Col>
          </Row>

          <Row className="pt-3">
            <Col lg="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={orders}
                    // isGlobalFilter={true}
                    // isAddOptions={false}
                    customPageSize={10}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AllEmployeeDetail
