import React from "react"
import ExcelJS from "exceljs"
import { UncontrolledTooltip } from "reactstrap"
import excelIcon from "../../../src/assets/images/excel.svg"

const LeadsDownload = ({ leads }) => {
  const moment = require("moment-timezone")

  // Utility function to format the amount as currency
  const formatAmountCurrency = (amount, currency) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currency,
    }).format(amount)
  }

  const downloadExcel = async () => {
    const currentDate = moment().tz("Asia/Karachi").format("YYYY-MM-DD")

    // Sort leads in descending order based on a specific key, e.g., 'courier_id'
    const sortedLeads = [...leads].sort((a, b) => {
      const key = "courier_id" // Change this to the desired key for sorting
      if (a[key] > b[key]) return -1
      if (a[key] < b[key]) return 1
      return 0
    })

    // Create a new workbook and add a worksheet
    const workbook = new ExcelJS.Workbook()
    const worksheet = workbook.addWorksheet("Leads")

    // Define the header row and apply styles
    const headers = Object.keys(leads[0]).filter(header => header !== "id")
    headers.push("Total Amount") // Add a new header for the calculated amount
    worksheet.addRow(headers)

    const headerRow = worksheet.getRow(1)
    headerRow.eachCell(cell => {
      cell.font = { bold: true }
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" }, // Yellow background
      }
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      }
    })

    // Add the sorted data rows, excluding the 'id' column
    sortedLeads.forEach(lead => {
      const parcelAmount = parseFloat(lead.total_price)
      const documentAmount = parseFloat(lead.docs_total_price)

      const PCVIPERCENT = parseFloat(lead.parcel_price) * 0.025
      const DCVIPERCENT = parseFloat(lead.doc_cvi_amount) * 0.025

      const PA_FOR_GST = (parcelAmount - PCVIPERCENT) * 0.15 + parcelAmount
      const DA_FOR_GST = (documentAmount - DCVIPERCENT) * 0.15 + documentAmount

      const totalAmount =
        lead.service_name === "Docs. Attestation" ? DA_FOR_GST : PA_FOR_GST

      const formattedTotalAmount = formatAmountCurrency(totalAmount, "PKR")

      // Add the lead data and calculated Total Amount
      const filteredLead = headers.map(header => {
        if (header === "Total Amount") {
          return formattedTotalAmount // Add the calculated total amount
        }
        return lead[header] || "" // Ensure it returns an empty string if no value is present
      })

      const row = worksheet.addRow(filteredLead)
      row.eachCell(cell => {
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        }
      })
    })

    // Adjust column widths to fit content
    headers.forEach((header, index) => {
      const lengths = sortedLeads.map(lead =>
        lead[header] ? lead[header].toString().length : 10
      )
      const maxLength = Math.max(...lengths, header.length)
      worksheet.getColumn(index + 1).width = maxLength < 10 ? 10 : maxLength + 2 // Adding a buffer for padding
    })

    // Generate a buffer and create a Blob from it
    const buffer = await workbook.xlsx.writeBuffer()
    const blob = new Blob([buffer], { type: "application/octet-stream" })

    // Create a link element to download the Blob
    const link = document.createElement("a")
    link.href = URL.createObjectURL(blob)
    link.download = `${currentDate}-Leads.xlsx`
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <div>
      <img
        src={excelIcon}
        alt="excel.svg"
        onClick={downloadExcel}
        style={{
          cursor: "pointer",
          border: "1px solid #bfc1c5",
          borderRadius: "5px",
          padding: "5px",
        }}
        id="excelIcon"
      />
      <UncontrolledTooltip placement="top" target="excelIcon">
        Excel
      </UncontrolledTooltip>
    </div>
  )
}

export default LeadsDownload
