import React, { useEffect, useState, useMemo } from "react"
import PropTypes from "prop-types"
import { Link, useNavigate, Redirect } from "react-router-dom"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons"

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Form,
  InputGroup,
  InputGroupText,
  FormGroup,
  Label,
  UncontrolledTooltip,
  Modal,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import classnames from "classnames"
import { connect } from "react-redux"
import withRouter from "components/Common/withRouter"

import {
  Pdate,
  Type,
  Value,
  ValueinUSD,
  Status,
  Coin,
  Tracked,
} from "./userstabledata"

import TableContainer from "../../components/Common/TableContainer"

// import img2 from "../../assets/images/no-time-off.59f54e64.svg"

//Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "assets/scss/datatables.scss"

// import { cryptoOrderData } from "../../../common/data";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import { getCryptoOrders } from "store/crypto/actions"

import toastr from "toastr"
import "toastr/build/toastr.min.css"
import { post } from "helpers/api_helper"
import axios from "axios"
import DeleteModal from "components/Common/DeleteModal"
import apiURl from "url"

const EcsUsers = props => {
  //meta title
  document.title = "Ecs Users"
  const navigate = useNavigate()

  const role_ = localStorage.getItem("role")
  if (role_ !== "admin") {
    navigate("/")
  }

  const [showPassword, setShowPassword] = useState(false)
  const [showFPassword, setShowFPassword] = useState(false)

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }
  const togglePasswordVisibility2 = () => {
    setShowFPassword(!showFPassword)
  }

  const [orders, setOrders] = useState([])
  const [accountModal, setAccountModal] = useState(false)
  const [fullname, setFullname] = useState(null)
  const [email, setEmail] = useState(null)
  const [city, setCity] = useState(null)
  const [password, setpassword] = useState(null)
  const [fpassword, setFpassword] = useState(null)
  const [role, setRole] = useState(null)

  const [editID, setEditID] = useState(null)

  const [userCode, setUserCode] = useState(null)

  const handleSubmit = e => {
    e.preventDefault()

    if (!fullname) {
      toastr.error("Full Name is Required")
      return
    }
    if (!email) {
      toastr.error("Email is Required")
      return
    }
    if (!city) {
      toastr.error("City is Required")
      return
    }
    if (!password || password !== fpassword) {
      toastr.error("Passwords do not match")
      return
    }
    if (!role || role === "" || role === null) {
      toastr.error("Role is Required")
      return
    }

    const userData = {
      fullname: fullname,
      email: email,
      city: city,
      password: password,
      role: role,
    }
    const URL = `${apiURl}/addUser`
    axios
      .post(URL, userData)
      .then(response => {
        if (response.data.status === "success") {
          toastr.success("User added successfully")
          setAccountModal(!accountModal)
          fetchUsers()
          setFullname("")
          setEmail("")
          setCity("")
          setpassword("")
          setFpassword("")
          setRole("")
        } else if (response.data.status === "failed") {
          fetchUsers()
          setFullname("")
          setEmail("")
          setCity("")
          setpassword("")
          setFpassword("")
          setRole("")
          toastr.error(response.data.message || "Failed to create user")
        }
      })
      .catch(error => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          toastr.error(error.response.data.message || "Failed to create user")
        } else if (error.request) {
          // The request was made but no response was received
          toastr.error(
            "Failed to create user. No response received from server."
          )
        } else {
          // Something happened in setting up the request that triggered an Error
          toastr.error("Failed to create user. Error in request setup.")
        }
      })
  }

  const handleEditUser = e => {
    e.preventDefault()

    if (!fullname) {
      toastr.error("Full Name is Required")
      return
    }
    if (!email) {
      toastr.error("Email is Required")
      return
    }
    if (!city) {
      toastr.error("City is Required")
      return
    }
    if (!password || password !== fpassword) {
      toastr.error("Passwords do not match")
      return
    }
    if (!role) {
      toastr.error("Role is Required")
      return
    }

    const userData = {
      edit_id: editID,
      fullname: fullname,
      email: email,
      city: city,
      password: password,
      role: role,
    }
    const URL = `${apiURl}/EditUser`
    axios
      .post(URL, userData)
      .then(response => {
        if (response.data.status === "success") {
          toastr.success("User Edit successfully")
          setAccountModal(!accountModal)
          setFullname("")
          setEmail("")
          setCity("")
          setpassword("")
          setFpassword("")
          setRole("")
          setUserCode("")
          fetchUsers()
        } else {
          toastr.error(response.data.message || "Failed to create user")
        }
      })
      .catch(error => {
        if (error.response) {
          toastr.error(error.response.data.message || "Failed to create user")
        } else if (error.request) {
          toastr.error(
            "Failed to create user. No response received from server."
          )
        } else {
          toastr.error("Failed to create user. Error in request setup.")
        }
      })
  }

  const fetchUsers = () => {
    setOrders([])
    const URL = `${apiURl}/fetchUsers`
    axios
      .get(URL)
      .then(response => {
        if (response.data.status === "success") {
          setOrders(response.data.users)
        } else {
          console.error("Failed to fetch users:", response.data.message)
        }
      })
      .catch(error => {
        console.error("Error fetching users:", error)
      })
  }

  useEffect(() => {
    fetchUsers()
  }, [])

  const addUser = () => {
    setAccountModal(!accountModal)
  }

  // Table Data

  const columns = useMemo(
    () => [
      {
        Header: "Code",
        accessor: "user_id",
        disableFilters: true,
        Cell: cellProps => {
          return <>{cellProps.value}</>
        },
      },
      {
        Header: "Full Name",
        accessor: "full_name",
        disableFilters: true,
        Cell: cellProps => {
          return <>{cellProps.value}</>
        },
      },
      {
        Header: "Email",
        accessor: "email_",
        disableFilters: true,
        Cell: cellProps => {
          return <Type {...cellProps} />
        },
      },
      {
        Header: "City",
        accessor: "city_",
        disableFilters: true,
        Cell: cellProps => {
          return <Type {...cellProps} />
        },
      },
      {
        Header: "Role",
        accessor: "role_",
        disableFilters: true,
        Cell: cellProps => {
          return <Coin {...cellProps} />
        },
      },
      {
        Header: "Action",
        accessor: "id",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <>
              <div className="d-flex gap-3">
                <Link
                  to="#"
                  onClick={() => fetchUserDataByID(cellProps.value)}
                  className="text-primary"
                >
                  <i className="mdi mdi-eye font-size-18" id="viewtooltip" />
                  <UncontrolledTooltip placement="top" target="viewtooltip">
                    View
                  </UncontrolledTooltip>
                </Link>
                <Link
                  to="#"
                  className="text-danger"
                  onClick={() => deleteUser(cellProps.value)}
                >
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                  />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </Link>
              </div>
            </>
          )
        },
      },
    ],
    []
  )

  const fetchUserDataByID = id => {
    const URL = `${apiURl}/fetchUserByID`
    axios
      .post(URL, { id })
      .then(response => {
        if (response.data.status === "success") {
          const UserDataByID = response.data.UserDataByID
          if (UserDataByID.length > 0) {
            setFullname(UserDataByID[0]?.full_name || "0")
            setEmail(UserDataByID[0]?.email_ || "0")
            setCity(UserDataByID[0]?.city_ || "0")
            setRole(UserDataByID[0]?.role_ || "0")
            setpassword(UserDataByID[0]?.password_ || "0")
            setFpassword(UserDataByID[0]?.password_ || "0")
            setUserCode(UserDataByID[0]?.user_id || "0")
            setAccountModal(!accountModal)
            setEditID(UserDataByID[0]?.id || id)
          } else {
            console.error("No service data found")
          }
        } else {
          console.error("Failed to fetch service data:", response.data.message)
        }
      })
      .catch(error => {
        console.error("Error fetching service data:", error)
      })
  }

  //Delete User
  const [deleteModal, setDeleteModal] = useState(false)
  const [del_id, setDel_id] = useState([])
  const deleteUser = id => {
    setDeleteModal(true)
    setDel_id(id)
  }
  const handleDeleteUser = () => {
    const URL = `${apiURl}/deleteUser`
    axios
    .post(URL, { id: del_id })
    .then(response => {
      if (response.data.status === "success") {
        setDeleteModal(false)
        toastr.success("User Delete successfully")
        fetchUsers()
      } else {
        setDeleteModal(false)
        console.error("Failed to Delete users:", response.data.message)
      }
    })
    .catch(error => {
      setDeleteModal(false);
      console.error("Error deleting user:", error);
    });
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          {/* <Breadcrumbs title="Crypto" breadcrumbItem="Orders" /> */}
          <Row>
            <Col xl="6">
              <div>
                <h5 className="mt-2">Users</h5>
              </div>
            </Col>
            <Col>
              <div className="float-end">
                <span>
                  <Button
                    type="button"
                    color="primary"
                    className="w-md btn btn-sm"
                    onClick={() => addUser()}
                  >
                    <i className="fa fa-fw fa-plus" />
                    Create User
                  </Button>
                </span>
              </div>
            </Col>
          </Row>

          <Row className="pt-3">
            <Col lg="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={orders}
                    // isGlobalFilter={true}
                    // isAddOptions={false}
                    customPageSize={10}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal
        isOpen={accountModal}
        role="dialog"
        size="lg"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={() => {
          setAccountModal(!accountModal)
        }}
      >
        <div>
          <Form>
            <ModalHeader
              toggle={() => {
                setAccountModal(!accountModal)
              }}
            >
              Create User
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col className="col-6">
                  <div className="mb-3">
                    <label>Full Name *</label>
                    <Input
                      className="form-control"
                      type="text"
                      placeholder="Name"
                      name="user_name"
                      value={fullname}
                      onChange={e => setFullname(e.target.value)}
                    ></Input>
                  </div>
                  <div className="mb-3">
                    <label>City</label>
                    <Input
                      className="form-control"
                      type="text"
                      placeholder="city"
                      name="user_city"
                      value={city}
                      onChange={e => setCity(e.target.value)}
                      //   disabled
                    ></Input>
                  </div>

                  <div className="mb-3">
                    <label>Password</label>
                    <InputGroup>
                      <Input
                        className="form-control"
                        type={showPassword ? "text" : "password"}
                        placeholder="password"
                        name="password_"
                        value={password}
                        onChange={e => setpassword(e.target.value)}
                        //   disabled
                      ></Input>
                      <InputGroupText
                        onClick={togglePasswordVisibility}
                        style={{ cursor: "pointer" }}
                      >
                        <FontAwesomeIcon
                          icon={showPassword ? faEyeSlash : faEye}
                        />
                      </InputGroupText>
                    </InputGroup>
                  </div>
                </Col>
                <Col className="col-6">
                  <div className="mb-3">
                    <label>Email</label>
                    <Input
                      className="form-control"
                      type="email"
                      placeholder="email"
                      name="user_email"
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                      //   disabled
                    ></Input>
                  </div>

                  <div className="mb-3">
                    <Label className="control-label" htmlFor="user_role">
                      Role
                    </Label>
                    <select
                      defaultValue="0"
                      className="form-select"
                      onChange={e => setRole(e.target.value)}
                      name="user_role"
                      value={role}
                    >
                      <option value={""}>select role...</option>
                      <option value="admin">Admin</option>
                      <option value="employee">Employee</option>
                      <option value="franchises">Franchises</option>
                      <option value="rider">Rider</option>
                    </select>
                  </div>
                  <div className="mb-3">
                    <label>Confirm Password</label>
                    <InputGroup>
                      <Input
                        className="form-control"
                        type={showFPassword ? "text" : "password"}
                        placeholder="password"
                        name="conf_password"
                        value={fpassword}
                        onChange={e => setFpassword(e.target.value)}
                        //   disabled
                      ></Input>
                      <InputGroupText
                        onClick={togglePasswordVisibility2}
                        style={{ cursor: "pointer" }}
                      >
                        <FontAwesomeIcon
                          icon={showFPassword ? faEyeSlash : faEye}
                        />
                      </InputGroupText>
                    </InputGroup>
                  </div>
                  {userCode && (
                    <div className="mb-3">
                      <label>Code</label>
                      <Input
                        className="form-control"
                        type="text"
                        placeholder=""
                        value={userCode}
                        disabled
                      ></Input>
                    </div>
                  )}
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button
                type="button"
                color="secondary"
                onClick={() => {
                  setAccountModal(!accountModal)
                  setFullname("")
                  setEmail("")
                  setpassword("")
                  setFpassword("")
                  setRole("")
                  setUserCode("")
                }}
              >
                Close
              </Button>
              {userCode ? (
                <Button type="submit" color="primary" onClick={handleEditUser}>
                  Submit Edit
                </Button>
              ) : (
                <Button type="submit" color="primary" onClick={handleSubmit}>
                  Submit
                </Button>
              )}
            </ModalFooter>
          </Form>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default EcsUsers
