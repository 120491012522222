import React, { useEffect, useState, useMemo } from "react"
import PropTypes from "prop-types"
import { Link, useNavigate } from "react-router-dom"

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  UncontrolledTooltip,
  Modal,
  Nav,
  NavItem,
  Badge,
  NavLink,
  Row,
  TabContent,
  TabPane,
  CardTitle,
} from "reactstrap"
import classnames from "classnames"
import { connect } from "react-redux"
import withRouter from "components/Common/withRouter"

import {
  Pdate,
  Type,
  Value,
  ValueinUSD,
  Status,
  Coin,
  Tracked,
} from "../EcsUsers/userstabledata"

import TableContainer from "../../components/Common/TableContainer"

// import img2 from "../../assets/images/no-time-off.59f54e64.svg"

//Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "assets/scss/datatables.scss"

import apiURl from "url"

// import { cryptoOrderData } from "../../../common/data";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import { getCryptoOrders } from "store/crypto/actions"

import toastr from "toastr"
import "toastr/build/toastr.min.css"
import { post } from "helpers/api_helper"
import axios from "axios"
import { faLariSign } from "@fortawesome/free-solid-svg-icons"

function formatAmountCurrency(amount, currency) {
  return amount.toLocaleString("en-US", {
    style: "currency",
    currency: currency,
    minimumFractionDigits: 2,
  })
}

const AllServicesDetail = props => {
  //meta title
  document.title = "Ecs Users"
  const navigate = useNavigate()

  const role_ = localStorage.getItem("role")
  if (role_ !== "admin") {
    navigate("/")
  }

  //Service Charges Data
  const [chargesID, setChargesID] = useState("")
  const [mainService, setMainService] = useState("")
  const [subService, setSubService] = useState("")
  const [serv_sector, setServ_sector] = useState("")
  const [serv_cahrges, setServ_charges] = useState("")
  const [parcel_weight, setParcelWeight] = useState("")
  const [extra_w_chrg, setExtra_w_chrg] = useState("")

  const [orders, setOrders] = useState([])
  const [orders2, setOrders2] = useState([])
  const [orders3, setOrders3] = useState([])

  const [accountModal, setAccountModal] = useState(false)
  const [accountModal2, setAccountModal2] = useState(false)
  const [chargesModal, setChargesModal] = useState(false)
  const [edit, setEdit] = useState(false)

  const [editID, setEditID] = useState("")

  //Service Data
  const [services_name, setServices_name] = useState("")
  const [S_price, setS_price] = useState("")
  const [pr_per_km, setPr_per_km] = useState("")
  const [pr_per_kg, setPr_per_kg] = useState("")
  const [serviceEditid, setserviceEditid] = useState("")

  const [Document, setDocument] = useState({
    sub_service_name: "",

    oto_wc_3docs: "",
    oto_wc_addocs: "",
    oto_ctc_3docs: "",
    oto_ctc_adddoc: "",

    otd_wc_3docs: "",
    otd_wc_addocs: "",
    otd_ctc_3docs: "",
    otd_ctc_adddoc: "",

    edit_id: "",
  })
  const handleDocAmount = e => {
    const { name, value } = e.target
    setDocument(prevData => ({
      ...prevData,
      [name]: value,
    }))
  }
  //Edit Sub Service
  const handleEditDoucumentEditSubm = async e => {
    e.preventDefault()
    if (Document.sub_service_name.length === 0) {
      toastr.error("Set Service Name")
    } else if (Document.oto_wc_3docs.length === 0) {
      toastr.error("Set All Prices")
    } else if (Document.oto_wc_addocs.length === 0) {
      toastr.error("Set All Prices")
    } else if (Document.oto_ctc_3docs.length === 0) {
      toastr.error("Set All Prices")
    } else if (Document.oto_ctc_adddoc.length === 0) {
      toastr.error("Set All Prices")
    } else if (Document.otd_wc_3docs.length === 0) {
      toastr.error("Set All Prices")
    } else if (Document.otd_wc_addocs.length === 0) {
      toastr.error("Set All Prices")
    } else if (Document.otd_ctc_3docs.length === 0) {
      toastr.error("Set All Prices")
    } else if (Document.otd_ctc_adddoc.length === 0) {
      toastr.error("Set All Prices")
    } else {
      try {
        const response = await axios.post(
          `${apiURl}/AddDocsServiceDataEdit`,
          Document
        )
        if (response.data.success) {
          // Handle success
          toastr.success("Edit successfully")
          setAccountModal(!accountModal)
          fetchServiceDocumentData()
          setDocument({
            sub_service_name: "",

            oto_wc_3docs: "",
            oto_wc_addocs: "",
            oto_ctc_3docs: "",
            oto_ctc_adddoc: "",

            otd_wc_3docs: "",
            otd_wc_addocs: "",
            otd_ctc_3docs: "",
            otd_ctc_adddoc: "",

            edit_id: "",
          })
        } else {
          // Handle failure
          console.error("Failed to Edit:", response.data.message)
        }
      } catch (error) {
        // Handle error
        toastr.error("Failed to Add: " + error.response.data.error)
      }
    }
  }
  //Add New Sub Service
  const handleNewDoucumentSubm = async e => {
    e.preventDefault()
    if (Document.sub_service_name.length === 0) {
      toastr.error("Set Service Name")
    } else if (Document.oto_wc_3docs.length === 0) {
      toastr.error("Set All Prices")
    } else if (Document.oto_wc_addocs.length === 0) {
      toastr.error("Set All Prices")
    } else if (Document.oto_ctc_3docs.length === 0) {
      toastr.error("Set All Prices")
    } else if (Document.oto_ctc_adddoc.length === 0) {
      toastr.error("Set All Prices")
    } else if (Document.otd_wc_3docs.length === 0) {
      toastr.error("Set All Prices")
    } else if (Document.otd_wc_addocs.length === 0) {
      toastr.error("Set All Prices")
    } else if (Document.otd_ctc_3docs.length === 0) {
      toastr.error("Set All Prices")
    } else if (Document.otd_ctc_adddoc.length === 0) {
      toastr.error("Set All Prices")
    } else {
      try {
        const response = await axios.post(
          `${apiURl}/AddDocsServiceData`,
          Document
        )
        if (response.data.success) {
          // Handle success
          toastr.success("Add successfully")
          setAccountModal(!accountModal)
          fetchServiceDocumentData()
          setDocument({
            sub_service_name: "",

            oto_wc_3docs: "",
            oto_wc_addocs: "",
            oto_ctc_3docs: "",
            oto_ctc_adddoc: "",

            otd_wc_3docs: "",
            otd_wc_addocs: "",
            otd_ctc_3docs: "",
            otd_ctc_adddoc: "",

            edit_id: "",
          })
        } else {
          // Handle failure
          console.error("Failed to Edit:", response.data.message)
        }
      } catch (error) {
        // Handle error
        toastr.error("Failed to Add: " + error.response.data.error)
      }
    }
  }

  //service Data
  const fetchServiceDatabyID = id => {
    const URL = `${apiURl}/fetchServiceDatabyID`
    axios
      .post(URL, { id })
      .then(response => {
        if (response.data.status === "success") {
          const serviceDataID = response.data.serviceDataID
          if (serviceDataID.length > 0) {
            setServices_name(serviceDataID[0].service_name)
            setS_price(serviceDataID[0].service_price)
            setPr_per_km(serviceDataID[0].price_per_km)
            setPr_per_kg(serviceDataID[0].price_per_kg)
            setserviceEditid(serviceDataID[0].id)
            setAccountModal2(!accountModal2)
          } else {
            console.error("No service data found")
          }
        } else {
          console.error("Failed to fetch service data:", response.data.message)
        }
      })
      .catch(error => {
        console.error("Error fetching service data:", error)
      })
  }

  //service Data
  const fetchService = () => {
    const URL = `${apiURl}/fetchServiceData`
    axios
      .get(URL)
      .then(response => {
        if (response.data.status === "success") {
          const serviceData = response.data.serviceData
          if (serviceData.length > 0) {
            setOrders(serviceData)
          } else {
            console.error("No service data found")
          }
        } else {
          console.error("Failed to fetch service data:", response.data.message)
        }
      })
      .catch(error => {
        console.error("Error fetching service data:", error)
      })
  }

  //Fetch Document DAta
  const fetchServiceDocumentData = () => {
    const URL = `${apiURl}/fetchServiceDocumentData`
    axios
      .get(URL)
      .then(response => {
        if (response.data.status === "success") {
          const DocserviceData = response.data.DocserviceData
          if (DocserviceData.length > 0) {
            setOrders2(DocserviceData)
          } else {
            console.error("No service data found")
          }
        } else {
          console.error("Failed to fetch service data:", response.data.message)
        }
      })
      .catch(error => {
        console.error("Error fetching service data:", error)
      })
  }

  const handleEditDocFee = id => {
    const edit_id = id
    const URL = `${apiURl}/fetchServiceDocumentDataID`
    axios
      .post(URL, { id: edit_id })
      .then(response => {
        if (response.data.status === "success") {
          const DocserviceDataID = response.data.DocserviceDataID
          if (DocserviceDataID.length > 0) {
            setDocument(prevData => ({
              ...prevData,

              sub_service_name: DocserviceDataID[0]?.doc_sub_service || "",

              oto_wc_3docs: DocserviceDataID[0]?.oto_wc_3docs || "0",
              oto_wc_addocs: DocserviceDataID[0]?.oto_wc_Addocs || "0",
              oto_ctc_3docs: DocserviceDataID[0]?.oto_ctc_3docs || "0",
              oto_ctc_adddoc: DocserviceDataID[0]?.oto_ctc_Addocs || "0",

              otd_wc_3docs: DocserviceDataID[0]?.otd_wc_3docs || "0",
              otd_wc_addocs: DocserviceDataID[0]?.otd_wc_Addocs || "0",
              otd_ctc_3docs: DocserviceDataID[0]?.otd_ctc_3docs || "0",
              otd_ctc_adddoc: DocserviceDataID[0]?.otd_ctc_Addocs || "0",

              edit_id: DocserviceDataID[0]?.id || "0",
            }))

            setAccountModal(!accountModal)
            setAddDocumentService(false)
          } else {
            console.error("No service data found")
          }
        } else {
          console.error("Failed to fetch service data:", response.data.message)
        }
      })
      .catch(error => {
        console.error("Error fetching service data:", error)
      })
  }

  useEffect(() => {
    fetchService()
    fetchServiceDocumentData()
  }, [])

  const deleteService = id => {
    const del_id = id
    const URL = `${apiURl}/deleteService`
    axios
      .post(URL, { id: del_id })
      .then(response => {
        if (response.data.status === "success") {
          fetchService()
          toastr.success("User Delete successfully")
        } else {
          console.error("Failed to Delete users:", response.data.message)
        }
      })
      .catch(error => console.log(error))
  }

  // Table Data
  const columns = useMemo(
    () => [
      {
        Header: "Service Name",
        accessor: "service_name",
        disableFilters: true,
        Cell: cellProps => {
          return <Type {...cellProps} />
        },
      },
      {
        Header: "Service PR",
        accessor: "service_price",
        disableFilters: true,
        Cell: cellProps => {
          const price = Number(cellProps.value).toFixed(2)
          return <>{price}</>
        },
      },
      {
        Header: "PR Per Km",
        accessor: "price_per_km",
        disableFilters: true,
        Cell: cellProps => {
          const price = Number(cellProps.value).toFixed(2)
          return <>{price}</>
        },
      },
      {
        Header: "PR Per Kg",
        accessor: "price_per_kg",
        disableFilters: true,
        Cell: cellProps => {
          const price = Number(cellProps.value).toFixed(2)
          return <>{price}</>
        },
      },
      {
        Header: "Action",
        accessor: "id",
        disableFilters: true,
        Cell: cellProps => {
          const id = cellProps.row.original.courier_id
          return (
            <>
              <div className="d-flex gap-3">
                <Link
                  to="#"
                  onClick={() => fetchServiceDatabyID(cellProps.value)}
                  className="text-primary"
                >
                  <i className="mdi mdi-eye font-size-18" id="viewtooltip2" />
                  <UncontrolledTooltip placement="top" target="viewtooltip2">
                    View
                  </UncontrolledTooltip>
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip2"
                    onClick={() => deleteService(cellProps.value)}
                  />
                  <UncontrolledTooltip placement="top" target="deletetooltip2">
                    Delete
                  </UncontrolledTooltip>
                </Link>
              </div>
            </>
          )
        },
      },
    ],
    []
  )

  const columns2 = useMemo(
    () => [
      {
        Header: "Service",
        accessor: "doc_sub_service",
        disableFilters: true,
        Cell: cellProps => {
          return <>{cellProps.value}</>
        },
      },
      {
        Header: "OTO(3Docs. WC)",
        accessor: "oto_wc_3docs",
        disableFilters: true,
        Cell: cellProps => {
          const price = formatAmountCurrency(parseFloat(cellProps.value), "PKR")
          return <>{price}</>
        },
      },
      {
        Header: "OTO(3Docs. CTC)",
        accessor: "oto_ctc_3docs",
        disableFilters: true,
        Cell: cellProps => {
          const price = formatAmountCurrency(parseFloat(cellProps.value), "PKR")
          return <>{price}</>
        },
      },
      {
        Header: "OTD(3Docs. WC)",
        accessor: "otd_wc_3docs",
        disableFilters: true,
        Cell: cellProps => {
          const price = formatAmountCurrency(parseFloat(cellProps.value), "PKR")
          return <>{price}</>
        },
      },
      {
        Header: "OTD(3Docs. CTC)",
        accessor: "otd_ctc_3docs",
        disableFilters: true,
        Cell: cellProps => {
          const price = formatAmountCurrency(parseFloat(cellProps.value), "PKR")
          return <>{price}</>
        },
      },

      {
        Header: "Action",
        accessor: "id34",
        disableFilters: true,
        Cell: cellProps => {
          const id = cellProps.row.original.id
          return (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-primary">
                  <i
                    className="mdi mdi-eye font-size-18"
                    id="deletetooltip"
                    onClick={() => {
                      handleEditDocFee(id)
                    }}
                  />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    View
                  </UncontrolledTooltip>
                </Link>
              </div>
            </>
          )
        },
      },
    ],
    []
  )

  const columns3 = useMemo(
    () => [
      {
        Header: "Main Service",
        accessor: "main_service",
        disableFilters: true,
        Cell: cellProps => {
          const ser = cellProps.value
          return (() => {
            switch (ser) {
              case "general":
                return <>General</>
            }
          })()
        },
      },
      {
        Header: "Sub Services",
        accessor: "sub_service",
        disableFilters: true,
        Cell: cellProps => {
          const subService = cellProps.value
          return (() => {
            switch (subService) {
              case "box":
                return <>Box</>
              case "overnight":
                return <>Over Night</>
              case "flyer":
                return <>Flyer</>
            }
          })()
        },
      },
      {
        Header: "Sector",
        accessor: "service_sector",
        disableFilters: true,
        Cell: cellProps => {
          const sector = cellProps.value
          return (() => {
            switch (sector) {
              case "wc":
                return <>Within City (wc)</>
              case "sz":
                return <>Same Zone (sz)</>
              case "dz":
                return <>Diff Zone (dz)</>
            }
          })()
        },
      },
      {
        Header: "Service Charges",
        accessor: "service_charges",
        disableFilters: true,
        Cell: cellProps => {
          return <>{formatAmountCurrency(parseFloat(cellProps.value), "PKR")}</>
        },
      },
      {
        Header: "Define Weight",
        accessor: "parcel_weight",
        disableFilters: true,
        Cell: cellProps => {
          return <>{cellProps.value}</>
        },
      },
      {
        Header: "Add Weight Charges",
        accessor: "extra_weight_charges",
        disableFilters: true,
        Cell: cellProps => {
          return <>{formatAmountCurrency(parseFloat(cellProps.value), "PKR")}</>
        },
      },

      {
        Header: "Action",
        accessor: "id34",
        disableFilters: true,
        Cell: cellProps => {
          const id = cellProps.row.original.id
          return (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-primary">
                  <i
                    className="mdi mdi-eye font-size-18"
                    id="deletetooltip"
                    onClick={() => {
                      handleEditCharges(id)
                    }}
                  />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    View
                  </UncontrolledTooltip>
                </Link>
              </div>
            </>
          )
        },
      },
    ],
    []
  )

  const handleEditDoucumentSubm2 = () => {
    const URL = `${apiURl}/EditServices`
    const data = {
      id: serviceEditid,
      service_name: services_name,
      service_price: S_price,
      price_per_km: pr_per_km,
      price_per_kg: pr_per_kg,
    }
    if (S_price === "") {
      toastr.error("Set Service Price")
    } else if (pr_per_km === "") {
      toastr.error("Set Price Per KM")
    } else if (pr_per_kg === "") {
      toastr.error("Set Price Per kg")
    } else {
      axios
        .post(URL, data)
        .then(response => {
          if (response.data.success) {
            fetchService()
            toastr.success("Add Successfully")
            setAccountModal2(!accountModal2)
          } else {
            toastr.error("Error in Adding")
          }
        })

        .catch(error => console.log(error))
    }
  }

  //Fecth service Charges

  //service  charges Data
  const fetchServiceCharges = () => {
    const URL = `${apiURl}/fetchServiceChargesData`
    axios
      .get(URL)
      .then(response => {
        if (response.data.status === "success") {
          const serviceChargesData = response.data.serviceChargesData
          if (serviceChargesData.length > 0) {
            setOrders3(serviceChargesData)
          } else {
            console.error("No service data found")
          }
        } else {
          console.error(
            "Failed to fetch service charges data:",
            response.data.message
          )
        }
      })
      .catch(error => {
        console.error("Error fetching service charges data:", error)
      })
  }
  useEffect(() => {
    fetchServiceCharges()
  }, [])

  const handleEditCharges = id => {
    const edit_id = id
    const URL =
      `${apiURl}/fetchServiceChargesDataByID`
    axios
      .post(URL, { id: edit_id })
      .then(response => {
        if (response.data.status === "success") {
          const serviceChargesDataID = response.data.serviceChargesDataID
          if (serviceChargesDataID.length > 0) {
            setMainService(serviceChargesDataID[0].main_service || "")
            setSubService(serviceChargesDataID[0].sub_service || "")
            setServ_sector(serviceChargesDataID[0].service_sector || "")
            setServ_charges(serviceChargesDataID[0].service_charges || "")
            setParcelWeight(serviceChargesDataID[0].parcel_weight || "")
            setExtra_w_chrg(serviceChargesDataID[0].extra_weight_charges || "")
            setChargesID(serviceChargesDataID[0].id)
            setChargesModal(!chargesModal)
          } else {
            console.error("No service data found")
          }
        } else {
          console.error("Failed to fetch service data:", response.data.message)
        }
      })
      .catch(error => {
        console.error("Error fetching service data:", error)
      })
  }

  //Submit Edit Services Charges
  const handleEditServicesCharges = () => {
    const URL = `${apiURl}/EditServiceCharges`
    const data = {
      id: chargesID,
      mainService: mainService,
      subService: subService,
      sector: serv_sector,
      serv_cahrges: serv_cahrges,
      parcel_weight: parcel_weight,
      extra_w_chrg: extra_w_chrg,
    }

    axios.post(URL, data).then(response => {
      if (response.data.success) {
        toastr.success("Edit Successfully")
        setMainService("")
        setSubService("")
        setServ_sector("")
        setServ_charges("")
        setParcelWeight("")
        setExtra_w_chrg("")
        setChargesModal(!chargesModal)
        fetchServiceCharges()
      } else {
        toastr.error("Error in Edit")
      }
    })
  }

  //Add New Document Service
  const [AddDocumentSevice, setAddDocumentService] = useState(false)
  const addNewDocumentService = () => {
    setDocument({
      sub_service_name: "",

      oto_wc_3docs: "",
      oto_wc_addocs: "",
      oto_ctc_3docs: "",
      oto_ctc_adddoc: "",

      otd_wc_3docs: "",
      otd_wc_addocs: "",
      otd_ctc_3docs: "",
      otd_ctc_adddoc: "",

      edit_id: "",
    })
    setAddDocumentService(true)
    setAccountModal(!accountModal)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          {/* <Breadcrumbs title="Crypto" breadcrumbItem="Orders" /> */}
          <Row>
            <Col xl={10}>
              <div>
                <h5 className="mt-2">Services Categories</h5>
              </div>
            </Col>
          </Row>

          {/* <Row className="pt-3">
            <Col lg="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={orders}
                    // isGlobalFilter={true}
                    // isAddOptions={false}
                    customPageSize={10}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row> */}
          <Row className="pt-3">
            <Col lg="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col xl={10}>
                      <CardTitle>Docs. Attestation Fee Detail</CardTitle>
                    </Col>
                    <Col xl={2}>
                      <div className="float-end">
                        <Link to="#" onClick={addNewDocumentService}>
                          <Button className="btn btn-sm" color="primary">
                            + Add Service
                          </Button>
                        </Link>
                      </div>
                    </Col>
                  </Row>

                  <TableContainer
                    columns={columns2}
                    data={orders2}
                    // isGlobalFilter={true}
                    // isAddOptions={false}
                    customPageSize={10}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className="pt-3">
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle>Service Charges</CardTitle>
                  <TableContainer
                    columns={columns3}
                    data={orders3}
                    // isGlobalFilter={true}
                    // isAddOptions={false}
                    customPageSize={10}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <Modal
        isOpen={accountModal2}
        size="lg"
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={() => {
          setAccountModal2(!accountModal2)
        }}
      >
        <div>
          <ModalHeader
            toggle={() => {
              setAccountModal2(!accountModal2)
            }}
          >
            Service Detail
          </ModalHeader>
          <ModalBody>
            <Row>
              <Row>
                <Col xl={6}>
                  <div className="mb-3">
                    <label>Services Name</label>
                    <Input
                      className="form-control"
                      type="text"
                      placeholder="service name"
                      name="payment_mode"
                      value={services_name}
                      onChange={e => setServices_name(e.target.value)}
                      disabled
                    ></Input>
                  </div>
                </Col>
                <Col xl={6}>
                  <div className="mb-3">
                    <label>Service Price</label>
                    <Input
                      className="form-control"
                      type="number"
                      placeholder="services price"
                      name="services price"
                      value={S_price}
                      onChange={e => setS_price(e.target.value)}
                    ></Input>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xl={6}>
                  <div className="mb-3">
                    <label>Price (per km) </label>
                    <Input
                      className="form-control"
                      type="number"
                      placeholder="price per km"
                      name="payment_mode"
                      value={pr_per_km}
                      onChange={e => setPr_per_km(e.target.value)}
                    ></Input>
                  </div>
                </Col>
                <Col xl={6}>
                  <div></div>
                  <div className="mb-3">
                    <label>Price (per kg)</label>
                    <Input
                      className="form-control"
                      type="number"
                      placeholder="price per kg"
                      name="payment_mode"
                      value={pr_per_kg}
                      onChange={e => setPr_per_kg(e.target.value)}
                    ></Input>
                  </div>
                </Col>
              </Row>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              color="secondary"
              onClick={() => {
                setAccountModal2(!accountModal2)
              }}
            >
              Close
            </Button>

            <Button
              type="button"
              color="primary"
              onClick={handleEditDoucumentSubm2}
            >
              Save Edit
            </Button>
          </ModalFooter>
        </div>
      </Modal>

      <Modal
        isOpen={accountModal}
        size="lg"
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={() => {
          setAccountModal(!accountModal)
        }}
      >
        <div>
          <ModalHeader
            toggle={() => {
              setAccountModal(!accountModal)
            }}
          >
            Document Cahrges Detail
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col xl={6}>
                <div className="mb-3">
                  <label>Service Name</label>
                  <Input
                    className="form-control"
                    type="text"
                    placeholder="Name"
                    name="sub_service_name"
                    value={Document.sub_service_name}
                    onChange={handleDocAmount}
                  ></Input>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xl={6}>
                <Row>
                  <CardTitle className="mb-3">
                    {" "}
                    Office To Office Collection
                  </CardTitle>
                  <h6 style={{ color: "blue" }} className="mb-3">
                    (Within City)
                  </h6>
                  <Col xl={6}>
                    <div className="mb-3">
                      <label>Up to 3 Docs</label>
                      <Input
                        className="form-control"
                        type="number"
                        placeholder="Rs"
                        name="oto_wc_3docs"
                        value={Document.oto_wc_3docs}
                        onChange={handleDocAmount}
                      ></Input>
                    </div>
                  </Col>
                  <Col xl={6}>
                    <div className="mb-3">
                      <label>Each Addl. Docs</label>
                      <Input
                        className="form-control"
                        type="number"
                        placeholder="Rs"
                        name="oto_wc_addocs"
                        value={Document.oto_wc_addocs}
                        onChange={handleDocAmount}
                      ></Input>
                    </div>
                  </Col>
                  <h6 style={{ color: "blue" }} className="mb-3">
                    (City to City)
                  </h6>
                  <Col xl={6}>
                    <div className="mb-3">
                      <label>Up to 3 Docs</label>
                      <Input
                        className="form-control"
                        type="number"
                        placeholder="Rs"
                        name="oto_ctc_3docs"
                        value={Document.oto_ctc_3docs}
                        onChange={handleDocAmount}
                      ></Input>
                    </div>
                  </Col>
                  <Col xl={6}>
                    <div className="mb-3">
                      <label>Each Addl. Docs</label>
                      <Input
                        className="form-control"
                        type="number"
                        placeholder="Rs"
                        name="oto_ctc_adddoc"
                        value={Document.oto_ctc_adddoc}
                        onChange={handleDocAmount}
                      ></Input>
                    </div>
                  </Col>
                </Row>
                <br />
                <br />
              </Col>
              <Col xl={6}>
                <Row>
                  <CardTitle className="mb-3">
                    {" "}
                    Office To Door Deliver
                  </CardTitle>
                  <h6 style={{ color: "blue" }} className="mb-3">
                    (Within City)
                  </h6>
                  <Col xl={6}>
                    <div className="mb-3">
                      <label>Up to 3 Docs</label>
                      <Input
                        className="form-control"
                        type="number"
                        placeholder="Rs"
                        name="otd_wc_3docs"
                        value={Document.otd_wc_3docs}
                        onChange={handleDocAmount}
                      ></Input>
                    </div>
                  </Col>
                  <Col xl={6}>
                    <div className="mb-3">
                      <label>Each Addl. Docs</label>
                      <Input
                        className="form-control"
                        type="number"
                        placeholder="Rs"
                        name="otd_wc_addocs"
                        value={Document.otd_wc_addocs}
                        onChange={handleDocAmount}
                      ></Input>
                    </div>
                  </Col>
                  <h6 style={{ color: "blue" }} className="mb-3">
                    (City to City)
                  </h6>
                  <Col xl={6}>
                    <div className="mb-3">
                      <label>Up to 3 Docs</label>
                      <Input
                        className="form-control"
                        type="number"
                        placeholder="Rs"
                        name="otd_ctc_3docs"
                        value={Document.otd_ctc_3docs}
                        onChange={handleDocAmount}
                      ></Input>
                    </div>
                  </Col>
                  <Col xl={6}>
                    <div className="mb-3">
                      <label>Each Addl. Docs</label>
                      <Input
                        className="form-control"
                        type="number"
                        placeholder="Rs"
                        name="otd_ctc_adddoc"
                        value={Document.otd_ctc_adddoc}
                        onChange={handleDocAmount}
                      ></Input>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              color="secondary"
              onClick={() => {
                setAccountModal(!accountModal)
              }}
            >
              Close
            </Button>
            {AddDocumentSevice ? (
              <Button
                type="button"
                color="primary"
                onClick={handleNewDoucumentSubm}
              >
                Submit
              </Button>
            ) : (
              <Button
                type="button"
                color="primary"
                onClick={handleEditDoucumentEditSubm}
              >
                Save Edit
              </Button>
            )}
          </ModalFooter>
        </div>
      </Modal>
      <Modal
        isOpen={chargesModal}
        size="lg"
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={() => {
          setChargesModal(!chargesModal)
        }}
      >
        <div>
          <ModalHeader
            toggle={() => {
              setChargesModal(!chargesModal)
            }}
          >
            Services Cahrges Detail
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col xl={12}>
                <Row>
                  <Col xl={3}>
                    <div className="mb-3">
                      <label>Main Services</label>
                      <Input
                        className="form-control"
                        type="text"
                        value={mainService}
                        onChange={e => setMainService(e.target.value)}
                      ></Input>
                    </div>
                  </Col>
                  <Col xl={3}>
                    <div className="mb-3">
                      <label>Sub Services</label>
                      <Input
                        className="form-control"
                        type="text"
                        value={subService}
                        onChange={e => setSubService(e.target.value)}
                      ></Input>
                    </div>
                  </Col>
                  <Col xl={3}>
                    <div className="mb-3">
                      <label>Services Sector</label>
                      <Input
                        className="form-control"
                        type="text"
                        value={serv_sector}
                        onChange={e => setServ_sector(e.target.value)}
                      ></Input>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xl={3}>
                    <div className="mb-3">
                      <label>Service Charges</label>
                      <Input
                        className="form-control"
                        type="number"
                        value={serv_cahrges}
                        onChange={e => setServ_charges(e.target.value)}
                      ></Input>
                    </div>
                  </Col>
                  <Col xl={3}>
                    <div className="mb-3">
                      <label>Parcel Weight</label>
                      <Input
                        className="form-control"
                        type="number"
                        name="payment_mode"
                        value={parcel_weight}
                        onChange={e => setParcelWeight(e.target.value)}
                      ></Input>
                    </div>
                  </Col>
                  <Col xl={3}>
                    <div></div>
                    <div className="mb-3">
                      <label>Extra weight Cahrges</label>
                      <Input
                        className="form-control"
                        type="number"
                        name="payment_mode"
                        value={extra_w_chrg}
                        onChange={e => setExtra_w_chrg(e.target.value)}
                      ></Input>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              color="secondary"
              onClick={() => {
                setChargesModal(!chargesModal)
              }}
            >
              Close
            </Button>

            <Button
              type="button"
              color="primary"
              onClick={handleEditServicesCharges}
            >
              Save Edit
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default AllServicesDetail
