import React, { useEffect, useState } from "react"
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete"

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  NavItem,
  NavLink,
  Form,
  Input,
  InputGroup,
  TabContent,
  TabPane,
} from "reactstrap"
import { Link, useNavigate, useParams } from "react-router-dom"
import classnames from "classnames"
import Select from "react-select"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import axios from "axios"

import toastr from "toastr"
import "toastr/build/toastr.min.css"
import { formatDate } from "@fullcalendar/react"
import { set } from "lodash"
import apiURl from "url"

const EditCourierInfo = props => {
  //meta title
  document.title = "Courier Info"
  const { id } = useParams()
  const CID = id

  //From Sender
  const navigate = useNavigate()
  const [isChecked, setIsChecked] = useState(false)
  const [Sname, setSname] = useState("")
  const [soption, setSoption] = useState([])
  const [Ename, setEname] = useState("")
  const [eoption, setEoption] = useState([])
  const [Rname, setRname] = useState("")
  const [roption, setRoption] = useState([])

  const [apiLoaded, setApiLoaded] = useState(false)
  const [service, setService] = useState([])

  const [oto, setOto] = useState(false)
  const [otd, setOtd] = useState(false)
  const [wc, setWc] = useState(false)
  const [ctc, setCtc] = useState(false)

  const [Cviamount, setCviAmount] = useState([])
  const [Cvipercent, setCvipercent] = useState([])

  //First Data
  const [activeTab, setactiveTab] = useState(1)
  const [passedSteps, setPassedSteps] = useState([1])
  const [riders, setRiders] = useState([])

  const [DocumentMode, setDocumentMode] = useState(false)

  const [FormData, setFormData] = useState({
    sender_full_name: "",
    sender_cnic: "",
    sender_phone: "",
    sender_address: "",

    receiver_full_name: "",
    receiver_phone: "",
    receiver_cnic: "",
    receiver_address: "",

    parcel_title: "",
    parcel_type: "",
    parcel_size: "",
    parcel_weight: "",
    parcel_price: "",
    parcel_info: "",

    status_: "",

    assign_rider: "",
    editId: CID,

    receive_location: "",
    send_location: "",

    service_name: "",
    service_price: "0",
    base_rateKm: "0",
    base_rateKg: "0",

    employee_name: "",

    s_lng: "0",
    s_lat: "0",

    r_lng: "0",
    r_lat: "0",

    total_rate_per_km: "0",
    total_rate_per_kg: "0",
    subtotal_: "0",

    //Documents Data
    office_door_option: "",
    city_options: "",
    no_of_docs: "0",
    Docs_subtotal_: "0",
    cvi_percent: false,
  })

  const handleParcel = e => {
    const { name, value } = e.target
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }))
  }

  //Handle Edit Submit
  const handleEditSubmit = async () => {
    try {
      const response = await axios.post(
        `${apiURl}/EditParcel`,
        FormData
      )
      if (response.data.status === "success") {
        // Handle success
        toastr.success("Parcel Edit successfully")
        navigate("/all_parcel_detail")
        // console.log("Parcel added successfully")
      } else {
        // Handle failure
        console.error("Failed to Edit parcel:", response.data.message)
      }
    } catch (error) {
      // Handle error
      console.error("Error Edit parcel:", error)
    }
  }
  //Fetch Riders
  const fetchRiders = () => {
    const URL = `${apiURl}/fetchRiders`
    axios
      .get(URL)
      .then(response => {
        if (response.data.status === "success") {
          setRiders(response.data.riders)
        } else {
          console.error("Failed to fetch riders:", response.data.message)
        }
      })
      .catch(error => {
        console.error("Error fetching riders:", error)
      })
  }

  //Fetch Parcel Info
  const fetchParcelinfoByID = () => {
    const URL = `${apiURl}/fetchParcelDetailsByID`
    const id = { id: CID }
    axios
      .post(URL, id)
      .then(response => {
        if (response.data.status === "success") {
          const parcelDetails = response.data.parcelDetailsByID[0]
          setFormData(prevFormData => ({
            ...prevFormData,
            sender_full_name: parcelDetails.sender_full_name,
            sender_cnic: parcelDetails.sender_cnic,
            sender_phone: parcelDetails.sender_phone,
            sender_address: parcelDetails.sender_address,
            receiver_full_name: parcelDetails.receiver_full_name,
            receiver_phone: parcelDetails.receiver_phone,
            receiver_cnic: parcelDetails.receiver_cnic,
            receiver_address: parcelDetails.receiver_address,
            parcel_title: parcelDetails.parcel_title,
            parcel_type: parcelDetails.parcel_type,
            parcel_size: parcelDetails.parcel_size,
            parcel_weight: parcelDetails.parcel_weight,
            parcel_price: parcelDetails.parcel_price,
            parcel_info: parcelDetails.parcel_info,
            assign_rider: parcelDetails.assign_rider,
            status_: parcelDetails.status_,

            employee_name: parcelDetails.employee_name,
            service_name: parcelDetails.service_name,

            send_location: parcelDetails.send_location,
            receive_location: parcelDetails.receive_location,

            subtotal_: parcelDetails.total_price,
          }))
          let Employee = {
            label: parcelDetails.employee_name,
            value: parcelDetails.employee_name,
          }
          setEname(Employee)

          let Service = {
            label: parcelDetails.service_name,
            value: parcelDetails.service_name,
          }
          setSname(Service)

          if (parcelDetails.service_name === "Docs. Attestation") {
            setDocumentMode(true)
          } else {
            setDocumentMode(false)
          }
        } else {
          console.error(
            "Failed to fetch parcel details:",
            response.data.message
          )
        }
      })
      .catch(error => {
        console.error("Error fetching parcel details:", error)
      })
  }

  const handleNext = () => {
    if (activeTab === 1) {
      if (FormData.sender_full_name.length === 0) {
        toastr.error("Full Name is Required")
      } else if (FormData.sender_phone.length === 0) {
        toastr.error("PH Number is Required")
      } else if (FormData.sender_address.length === 0) {
        toastr.error("Address is Required")
      } else {
        setactiveTab(activeTab + 1)
      }
    } else if (activeTab === 2) {
      if (FormData.receiver_full_name.length === 0) {
        toastr.error("Full Name is Required")
      } else if (FormData.receiver_phone.length === 0) {
        toastr.error("PH Number is Required")
      } else if (FormData.receiver_address.length === 0) {
        toastr.error("Address is Required")
      } else {
        setactiveTab(activeTab + 1)
      }
    } else if (activeTab === 3) {
      if (FormData.parcel_title.length === 0) {
        toastr.error("Title is Required")
      } else if (FormData.parcel_type.length === 0) {
        toastr.error("Parcel Type is Required")
      } else if (FormData.parcel_price.length === 0) {
        toastr.error("Price is Required")
      } else if (FormData.parcel_info.length === 0) {
        toastr.error("Parcel Info is Required")
      } else {
        setactiveTab(activeTab + 1)
      }
    }
  }

  //Fetch Only Service
  const fetchServiceName = () => {
    const URL = `${apiURl}/fetchServiceData`
    axios
      .get(URL)
      .then(response => {
        if (response.data.status === "success") {
          const options = response.data.serviceData.map(row => ({
            label: row.service_name,
            value: row.service_name,
          }))
          setSoption(options)
          const serviceData = response.data.serviceData
          if (serviceData.length > 0) {
            setService(serviceData)
          } else {
            console.error("No service data found")
          }
        } else {
          console.error("Failed to fetch service data:", response.data.message)
        }
      })
      .catch(error => {
        console.error("Error fetching service data:", error)
      })
  }

  //Fetch Services Detial
  const fetchServiceData = Sname => {
    const URL = `${apiURl}/fetchServiceData`
    axios
      .get(URL)
      .then(response => {
        if (response.data.status === "success") {
          const serviceData = response.data.serviceData
          if (serviceData.length > 0) {
            // Set the service data in state
            setService(serviceData)

            // Find the selected service object
            const selectedService = serviceData.find(
              service => service.service_name === Sname
            )

            // If selected service exists, set base_rateKg and base_rateKm
            if (selectedService) {
              setFormData(prevData => ({
                ...prevData,
                base_rateKg: selectedService.price_per_kg || "0",
                base_rateKm: selectedService.price_per_km || "0",
                service_price: selectedService.service_price || "0",
              }))
            } else {
              console.error("Selected service not found in service data")
            }
          } else {
            console.error("No service data found")
          }
        } else {
          console.error("Failed to fetch service data:", response.data.message)
        }
      })
      .catch(error => {
        console.error("Error fetching service data:", error)
      })
  }

  //Handel Sender Location
  const handleChange = address => {
    setFormData({ ...FormData, send_location: address })
  }
  const handleSelect = async address => {
    setFormData({ ...FormData, send_location: address })
    try {
      const results = await geocodeByAddress(address)
      const latLng = await getLatLng(results[0])
      const { lng, lat } = latLng // Sender coordinates
      console.log("Sender Latitude and Longitude:", latLng)

      // Set sender coordinates in FormData
      setFormData(prevData => ({
        ...prevData,
        s_lng: lng,
        s_lat: lat,
      }))

      // Check if receiver location is set
      if (FormData.r_lat !== 0 && FormData.r_lng !== 0) {
        calculateDistance(lat, lng, FormData.r_lat, FormData.r_lng)
      }
    } catch (error) {
      console.error("Error", error)
    }
  }

  //Handle receiver Location
  const handleChange2 = address => {
    setFormData({ ...FormData, receive_location: address })
  }
  const handleSelect2 = async address => {
    setFormData({ ...FormData, receive_location: address })
    try {
      const results = await geocodeByAddress(address)
      const latLng = await getLatLng(results[0])
      const { lng, lat } = latLng // Receiver coordinates
      console.log("Receiver Latitude and Longitude:", latLng)

      // Set receiver coordinates in FormData
      setFormData(prevData => ({
        ...prevData,
        r_lng: lng,
        r_lat: lat,
      }))

      // Check if sender location is set
      if (FormData.s_lat !== 0 && FormData.s_lng !== 0) {
        calculateDistance(FormData.s_lat, FormData.s_lng, lat, lng)
      }
    } catch (error) {
      console.error("Error", error)
    }
  }

  //Handle Service
  const [serviceOption, setServiceOption] = useState({
    oto_wc_3docs: "",
    oto_wc_addocs: "",
    oto_ctc_3docs: "",
    oto_ctc_adddoc: "",

    otd_wc_3docs: "",
    otd_wc_addocs: "",
    otd_ctc_3docs: "",
    otd_ctc_adddoc: "",
  })
  const fetchDocumentData = () => {
    const URL = `${apiURl}/fetchDocumentService`
    axios
      .get(URL)
      .then(response => {
        if (response.data.status === "success") {
          const documentData = response.data.documentData
          setServiceOption({
            oto_wc_3docs: documentData[0]?.oto_wc_3docs || "0",
            oto_wc_addocs: documentData[0]?.oto_wc_Addocs || "0",
            oto_ctc_3docs: documentData[0]?.oto_ctc_3docs || "0",
            oto_ctc_adddoc: documentData[0]?.oto_ctc_Addocs || "0",

            otd_wc_3docs: documentData[0]?.otd_wc_3docs || "0",
            otd_wc_addocs: documentData[0]?.otd_wc_Addocs || "0",
            otd_ctc_3docs: documentData[0]?.otd_ctc_3docs || "0",
            otd_ctc_adddoc: documentData[0]?.otd_ctc_Addocs || "0",
          })
        } else {
          console.error("Failed to fetch Employee:", response.data.message)
        }
      })
      .catch(error => {
        console.error("Error fetching Employee:", error)
      })
  }
  const handleService = selectedOption => {
    setFormData(prevData => ({
      ...prevData,
      service_name: selectedOption.value,
    }))
    if (selectedOption.value === "Docs. Attestation") {
      setDocumentMode(true)
      fetchDocumentData()
    } else {
      setDocumentMode(false)
      setFormData(prevData => ({
        ...prevData,

        Docs_subtotal_: "0",
        office_door_option: "",
        city_options: "",
        no_of_docs: "0",
      }))
      setIsChecked(false)
    }
    setSname(selectedOption)
    fetchServiceData(selectedOption.value)
  }

  //Handle Employe
  const fetchEmployee = () => {
    const URL = `${apiURl}/fetchEmployeeData`
    axios
      .get(URL)
      .then(response => {
        if (response.data.status === "success") {
          const EmployeeOption = response.data.employeeData.map(row => ({
            label: row.em_name,
            value: row.em_name,
          }))
          setEoption(EmployeeOption)
        } else {
          console.error("Failed to fetch Employee:", response.data.message)
        }
      })
      .catch(error => {
        console.error("Error fetching Employee:", error)
      })
  }
  const handleEmployee = selectedOption => {
    setFormData(prevData => ({
      ...prevData,
      employee_name: selectedOption.value,
    }))
    setEname(selectedOption)
  }

  useEffect(() => {
    fetchRiders()
    fetchParcelinfoByID()
    fetchEmployee()
    fetchServiceName()
  }, [])

  // Fetch Documents Service
  const handleCheckboxChange = event => {
    const { checked } = event.target
    setIsChecked(checked)
    if (checked) {
      setFormData(prevData => ({
        ...prevData,
        cvi_percent: true,
      }))
    } else {
      setFormData(prevData => ({
        ...prevData,
        cvi_percent: false,
      }))
    }
  }

  useEffect(() => {
    if (isChecked) {
      const fetchCVI = async () => {
        try {
          const URL = `${apiURl}/fetchCVIData?service_name=${FormData.service_name}`
          const response = await axios.get(URL)

          if (response.data.status === "success") {
            const CVIdata = response.data.CVIdata
            if (CVIdata.length > 0) {
              setCviAmount(CVIdata[0]?.define_amount || "0")
              setCvipercent(CVIdata[0]?.cvi_percent || "0")
            } else {
              console.error("No service data found")
            }
          } else {
            console.error(
              "Failed to fetch service data:",
              response.data.message
            )
          }
        } catch (error) {
          console.error("Error fetching service data:", error)
        }
      }

      fetchCVI()
    }
  }, [isChecked, FormData.service_name])

  //Set Location and weight Rate Caluclation
  useEffect(() => {
    if (
      FormData.total_rate_per_kg &&
      FormData.total_rate_per_km &&
      FormData.service_price
    ) {
      const TPperkm = parseFloat(FormData.total_rate_per_km)
      const TPperkg = parseFloat(FormData.total_rate_per_kg)
      const TSprice = parseFloat(FormData.service_price)
      const Subtotal = TPperkg + TPperkm + TSprice
      setFormData(prevData => ({
        ...prevData,
        subtotal_: Subtotal.toFixed(2),
      }))
    } else {
      setFormData(prevData => ({
        ...prevData,
        subtotal_: "0",
      }))
    }
  }, [
    FormData.total_rate_per_kg,
    FormData.total_rate_per_km,
    FormData.service_price,
  ])

  useEffect(() => {
    if (
      FormData.parcel_weight &&
      FormData.base_rateKg &&
      FormData.base_rateKm
    ) {
      const weight = parseFloat(FormData.parcel_weight)
      const pricePerKg = parseFloat(FormData.base_rateKg)
      const totalWeightP = weight * pricePerKg

      setFormData(prevData => ({
        ...prevData,
        total_rate_per_kg: totalWeightP,
      }))
    }
  }, [FormData.base_rateKg])

  const calculateDistance = (lat1, lon1, lat2, lon2, ratePerKm) => {
    const R = 6371 // Radius of the Earth in kilometers
    const dLat = (lat2 - lat1) * (Math.PI / 180) // Convert degrees to radians
    const dLon = (lon2 - lon1) * (Math.PI / 180) // Convert degrees to radians
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * (Math.PI / 180)) *
        Math.cos(lat2 * (Math.PI / 180)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2)
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
    const distance = R * c // Distance in kilometers
    const totalPrice = ratePerKm * distance // Calculate total price
    return totalPrice.toFixed(2) // Convert totalPrice to fixed 2 decimal places
  }

  useEffect(() => {
    // Check if FormData.base_rateKm is set and call calculateDistance accordingly
    if (
      FormData.s_lat &&
      FormData.s_lng &&
      FormData.r_lat &&
      FormData.r_lng &&
      FormData.base_rateKm
    ) {
      const totalPrice = calculateDistance(
        FormData.s_lat,
        FormData.s_lng,
        FormData.r_lat,
        FormData.r_lng,
        parseFloat(FormData.base_rateKm)
      )

      setFormData(prevData => ({
        ...prevData,
        total_rate_per_km: totalPrice,
      }))
    }
  }, [
    FormData.s_lat,
    FormData.s_lng,
    FormData.r_lat,
    FormData.r_lng,
    FormData.base_rateKm,
  ])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="View Courier" breadcrumbItem=" Edit Courier" />

          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <CardTitle>Service Info</CardTitle>
                  <Form>
                    <Row>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label>Employee</Label>
                          <Select
                            value={Ename}
                            onChange={handleEmployee}
                            name="employee_name"
                            options={eoption}
                            className="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label>Services</Label>
                          <Select
                            value={Sname}
                            onChange={handleService}
                            name="service_name"
                            options={soption}
                            className="select2-selection"
                          />
                        </div>
                      </Col>
                    </Row>
                    {DocumentMode ? (
                      <>
                        <Row>
                          <Col lg={3}>
                            <div className="mb-3">
                              <label>Delivery Options</label>
                              <select
                                className="form-select"
                                name="office_door_option"
                                value={FormData.office_door_option}
                                onChange={handleOfficDoorService}
                              >
                                <option value="">Select...</option>
                                <option value="oto_">Office to Office</option>
                                <option value="otd_">Office to Door</option>
                              </select>
                            </div>
                          </Col>
                          <Col lg={3}>
                            <div className="mb-3">
                              <label>Delivery Options</label>
                              <select
                                className="form-select"
                                name="city_options"
                                value={FormData.city_options}
                                onChange={handlecityoptions}
                              >
                                <option value="">Select...</option>
                                <option value="wc_">With-in City</option>
                                <option value="ctc_">City To City</option>
                              </select>
                            </div>
                          </Col>
                          <Col lg={3}>
                            <div className="mb-3">
                              <Label htmlFor="no_of_docs">No Of Docs.</Label>
                              <Input
                                min={1}
                                type="number"
                                className="form-control"
                                id="no_of_docs"
                                placeholder="no."
                                name="no_of_docs"
                                value={FormData.no_of_docs}
                                onChange={handledocsNo}
                              />
                            </div>
                          </Col>
                          <Col lg={3}>
                            <div className="form-check mt-4">
                              <Input
                                type="checkbox"
                                className="form-check-input"
                                id="invalidCheck"
                                onChange={handleCheckboxChange}
                                value={FormData.cvi_percent}
                              />
                              <Label
                                className="form-check-label"
                                htmlFor="invalidCheck"
                              >
                                {" "}
                                CVI (2.5 %)
                              </Label>
                            </div>
                          </Col>
                        </Row>
                      </>
                    ) : (
                      <Row>
                        <Col lg="6">
                          <div className="mb-3">
                            <Label htmlFor="address_">Send Location</Label>
                            <PlacesAutocomplete
                              value={FormData.send_location}
                              onChange={handleChange}
                              onSelect={handleSelect}
                            >
                              {({
                                getInputProps,
                                suggestions,
                                getSuggestionItemProps,
                                loading,
                              }) => (
                                <div>
                                  <input
                                    {...getInputProps({
                                      placeholder: "Set send location...",
                                      className: "form-control",
                                      id: "address_",
                                    })}
                                  />
                                  <div>
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map((suggestion, index) => {
                                      const className = suggestion.active
                                        ? "suggestion-item--active"
                                        : "suggestion-item"
                                      const style = suggestion.active
                                        ? {
                                            backgroundColor: "#fafafa",
                                            cursor: "pointer",
                                          }
                                        : {
                                            backgroundColor: "#ffffff",
                                            cursor: "pointer",
                                          }
                                      return (
                                        <div
                                          key={index}
                                          {...getSuggestionItemProps(
                                            suggestion,
                                            {
                                              className,
                                              style,
                                            }
                                          )}
                                        >
                                          <i className="bx bx-map"></i>
                                          <span>{suggestion.description}</span>
                                        </div>
                                      )
                                    })}
                                  </div>
                                </div>
                              )}
                            </PlacesAutocomplete>
                          </div>
                        </Col>
                        <Col lg="6">
                          <div className="mb-3">
                            <Label htmlFor="address2_">Receive Location</Label>
                            <PlacesAutocomplete
                              value={FormData.receive_location}
                              onChange={handleChange2}
                              onSelect={handleSelect2}
                            >
                              {({
                                getInputProps,
                                suggestions,
                                getSuggestionItemProps,
                                loading,
                              }) => (
                                <div>
                                  <input
                                    {...getInputProps({
                                      placeholder: "Set receive location...",
                                      className: "form-control",
                                      id: "address2_",
                                    })}
                                  />
                                  <div>
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map((suggestion, index) => {
                                      const className = suggestion.active
                                        ? "suggestion-item--active"
                                        : "suggestion-item"
                                      const style = suggestion.active
                                        ? {
                                            backgroundColor: "#fafafa",
                                            cursor: "pointer",
                                          }
                                        : {
                                            backgroundColor: "#ffffff",
                                            cursor: "pointer",
                                          }
                                      return (
                                        <div
                                          key={index}
                                          {...getSuggestionItemProps(
                                            suggestion,
                                            {
                                              className,
                                              style,
                                            }
                                          )}
                                        >
                                          <i className="bx bx-map"></i>
                                          <span>{suggestion.description}</span>
                                        </div>
                                      )
                                    })}
                                  </div>
                                </div>
                              )}
                            </PlacesAutocomplete>
                          </div>
                        </Col>
                      </Row>
                    )}
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* Send And Receive Data */}
          <Row>
            <Col xl={6}>
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Sender Information</h4>
                  <Form>
                    <Row>
                      <Col lg="8">
                        <div className="mb-3">
                          <Label htmlFor="formrow-firstname-Input">
                            Full Name *
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-firstname-Input"
                            placeholder="Enter Your First Name"
                            name="sender_full_name"
                            value={FormData.sender_full_name}
                            onChange={handleParcel}
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label htmlFor="formrow-email-Input">Phone *</Label>
                          <Input
                            type="number"
                            className="form-control"
                            id="formrow-email-Input"
                            placeholder="Ph No Here"
                            name="sender_phone"
                            value={FormData.sender_phone}
                            onChange={handleParcel}
                          />
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label htmlFor="cnic_">CNIC</Label>
                          <Input
                            type="number"
                            className="form-control"
                            id="cnic_"
                            placeholder="Enter Your CNIC"
                            name="sender_cnic"
                            value={FormData.sender_cnic}
                            onChange={handleParcel}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12">
                        <div className="mb-3">
                          <Label htmlFor="address_">Address *</Label>
                          <Input
                            type="textarea"
                            id="address_"
                            rows="3"
                            placeholder="Address"
                            name="sender_address"
                            value={FormData.sender_address}
                            onChange={handleParcel}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
            <Col xl={6}>
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Receiver Information</h4>
                  <Form>
                    <Row>
                      <Col lg="8">
                        <div className="mb-3">
                          <Label htmlFor="formrow-firstname-Input">
                            Full Name *
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-firstname-Input"
                            placeholder="Enter Your First Name"
                            name="receiver_full_name"
                            value={FormData.receiver_full_name}
                            onChange={handleParcel}
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label htmlFor="formrow-email-Input">Phone *</Label>
                          <Input
                            type="number"
                            className="form-control"
                            id="formrow-email-Input"
                            placeholder="Ph No Here"
                            name="receiver_phone"
                            value={FormData.receiver_phone}
                            onChange={handleParcel}
                          />
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label htmlFor="cnic_">CNIC</Label>
                          <Input
                            type="number"
                            className="form-control"
                            id="cnic_"
                            placeholder="Enter Your CNIC"
                            name="receiver_cnic"
                            value={FormData.receiver_cnic}
                            onChange={handleParcel}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12">
                        <div className="mb-3">
                          <Label htmlFor="address_">Address *</Label>
                          <Input
                            type="textarea"
                            id="address_"
                            rows="3"
                            placeholder="Address"
                            name="receiver_address"
                            value={FormData.receiver_address}
                            onChange={handleParcel}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* Parcel Info */}
          <Row>
            <Card>
              <CardBody>
                <h4 className="card-title mb-4">Parcel Info</h4>
                <Row>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label htmlFor="parcel_title">Title *</Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="parcel_title"
                        placeholder="Title"
                        name="parcel_title"
                        value={FormData.parcel_title}
                        onChange={handleParcel}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label htmlFor="parcel_type">Type *</Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="parcel_type"
                        placeholder="Type"
                        name="parcel_type"
                        value={FormData.parcel_type}
                        onChange={handleParcel}
                      />
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="mb-3">
                      <Label htmlFor="parcel_size">Size</Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="parcel_size"
                        placeholder="Size"
                        name="parcel_size"
                        value={FormData.parcel_size}
                        onChange={handleParcel}
                      />
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="mb-3">
                      <Label htmlFor="parcel_weight">Weight</Label>
                      <Input
                        type="number"
                        className="form-control"
                        id="parcel_weight"
                        placeholder="Weight"
                        name="parcel_weight"
                        value={FormData.parcel_weight}
                        onChange={handleParcel}
                      />
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="mb-3">
                      <Label htmlFor="parcel_price">Price *</Label>
                      <Input
                        type="number"
                        className="form-control"
                        id="parcel_price"
                        placeholder="Price"
                        name="parcel_price"
                        value={FormData.parcel_price}
                        onChange={handleParcel}
                      />
                    </div>
                  </Col>
                </Row>
                <div className="mb-3">
                  <Label htmlFor="parcel_info">Parcel Info *</Label>
                  <Input
                    type="textarea"
                    id="parcel_info"
                    rows="3"
                    placeholder="Parcel Information"
                    name="parcel_info"
                    value={FormData.parcel_info}
                    onChange={handleParcel}
                  />
                </div>
                <Row>
                  <Col lg="3">
                    <div className="mb-3">
                      <Label htmlFor="assign_">Assign To</Label>
                      <select
                        defaultValue="0"
                        className="form-select"
                        onChange={handleParcel}
                        name="assign_rider"
                        value={FormData.assign_rider}
                      >
                        <option value="">Select Rider...</option>
                        {riders.length > 0 ? (
                          riders
                            .filter(e => !!e)
                            .map((e, index) => (
                              <option key={index} value={e.full_name}>
                                {e.full_name}
                              </option>
                            ))
                        ) : (
                          <option>No Data found</option>
                        )}
                      </select>
                    </div>
                  </Col>
                  <Col lg={3}>
                    <div className="mb-3">
                      <Label htmlFor="Pstatus_">Status</Label>
                      <select
                        defaultValue="0"
                        className="form-select"
                        onChange={handleParcel}
                        name="status_"
                        value={FormData.status_}
                      >
                        <option
                          value="new"
                          selected={formatDate.status_ === "new"}
                        >
                          New
                        </option>
                        <option
                          value="in-progress"
                          selected={formatDate.status_ === "in-progress"}
                        >
                          In Progress
                        </option>
                        <option
                          value="delivered"
                          selected={formatDate.status_ === "delivered"}
                        >
                          Delivered
                        </option>
                      </select>
                    </div>
                  </Col>
                  <Col lg="6">
                    <div className="mb-3 float-end">
                      <Label htmlFor="subtotal_">Total Price (pkr)</Label>
                      <Input
                        type="number"
                        className="form-control"
                        id="subtotal_"
                        placeholder="per km"
                        name="subtotal_"
                        value={FormData.subtotal_}
                        onChange={handleParcel}
                        disabled
                      />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Row>

          {/* Control */}
          <Row className="mb-3">
            <Col xl={4}>
              <Button
                color="danger"
                className="btn btn-danger waves-effect waves-light"
                // onClick={close}
              >
                Close
              </Button>
            </Col>
            <Col xl={8}>
              <div className="float-end">
                <Button
                  color="success"
                  className="btn btn-secondary waves-effect waves-light"
                  onClick={handleEditSubmit}
                >
                  Save & Close
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EditCourierInfo
