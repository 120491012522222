import React, { useEffect, useState, useMemo, useRef } from "react"
import { useReactToPrint } from "react-to-print"
import QRCode from "qrcode.react"

import PropTypes from "prop-types"
import { Link, useNavigate, useParams } from "react-router-dom"

import "../../assets/style/style.css"

import {
  Card,
  CardBody,
  Col,
  Container,
  UncontrolledTooltip,
  Badge,
  Row,
} from "reactstrap"

import { Type, Coin } from "../EcsUsers/userstabledata"

import TableContainer from "../../components/Common/TableContainer"

// import img2 from "../../assets/images/no-time-off.59f54e64.svg"

//Date Picker
import "react-datepicker/dist/react-datepicker.css"
import "assets/scss/datatables.scss"

// import { cryptoOrderData } from "../../../common/data";

//Import Breadcrumb

import toastr from "toastr"
import "toastr/build/toastr.min.css"
import axios from "axios"
import profile from "assets/images/ecs234.jpg"
import apiURl from "url"

const AllCourierDetail = props => {
  //meta title
  document.title = "All Courier"

  const { id } = useParams()

  const role = localStorage.getItem("role")
  const user_id = localStorage.getItem("login_user_id")

  const printRef = useRef()
  //Table Data
  const [orders, setOrders] = useState([])
  //Print Data
  const [DocumentMode, setDocumentMode] = useState(false)
  const [editID, setEditID] = useState(null)
  const [print, setPrint] = useState(false)
  const [printClicked, setPrintClicked] = useState(false) // Flag to track print button click
  const [cvimode, setCvimode] = useState(false)
  const [trackID, setTrackID] = useState("")
  const [fdata, setFdata] = useState({
    s_name: "",
    s_phone: "",
    s_address: "",

    r_name: "",
    r_phone: "",
    r_address: "",

    staus: "",

    b_date: "",

    service_name: "",
    employee_name: "",
    employee_id: "",

    ecs_track_id: "",

    ser_charges: "",
    Dser_caharges: "",

    content_name: "",
    parcel_price: "",
    parcel_weight: "",
    parcel_cvi_status: "",
    no_of_products: "",

    from_loca: "",
    rec_loca: "",

    no_of_doc: "",
    office_del: "",
    city_del: "",
    docs_cvi_status: "",
    docs_detail: "",
    docs_cvi_amount: "",

    pay_method: "",
  })

  //Fetch Print Data

  const ParceldataforPrint = async id => {
    try {
      const URL = `${apiURl}/fetchParcelDetailsByID`
      const response = await axios.post(URL, { id })
      if (response.data.status === "success") {
        const parcelDetailsByID = response.data.parcelDetailsByID
        const value = parcelDetailsByID.map(e => e.docs_cvi_value)
        setCvimode(value[0] == "1" ? true : false)
        if (parcelDetailsByID.length > 0) {
          setFdata(prevData => ({
            ...prevData,
            s_name: parcelDetailsByID[0].sender_full_name || "",
            s_phone: parcelDetailsByID[0].sender_phone || "",
            s_address: parcelDetailsByID[0].sender_address || "",

            r_name: parcelDetailsByID[0].receiver_full_name || "",
            r_phone: parcelDetailsByID[0].receiver_phone || "",
            r_address: parcelDetailsByID[0].receiver_address || "",

            staus: parcelDetailsByID[0].status_ || "",

            b_date: parcelDetailsByID[0].created_date || "",

            service_name: parcelDetailsByID[0].service_name || "",
            employee_name: parcelDetailsByID[0].employee_name || "",
            employee_id: parcelDetailsByID[0].employee_id || "",

            ecs_track_id: parcelDetailsByID[0].track_id || "",

            ser_charges: parcelDetailsByID[0].total_price || "",
            Dser_caharges: parcelDetailsByID[0].docs_total_price || "",

            from_loca: parcelDetailsByID[0].send_location || "",
            rec_loca: parcelDetailsByID[0].receive_location || "",

            content_name: parcelDetailsByID[0].parcel_info || "",
            parcel_price: parcelDetailsByID[0].parcel_price || "",
            parcel_weight: parcelDetailsByID[0].parcel_weight || "",
            parcel_cvi_status: parcelDetailsByID[0].parcel_cvi_value || "",
            no_of_products: parcelDetailsByID[0].no_of_products || "",

            no_of_doc: parcelDetailsByID[0].no_of_docs || "",
            office_del: parcelDetailsByID[0].docs_office_type || "",
            city_del: parcelDetailsByID[0].docs_city_type || "",
            docs_cvi_status: parcelDetailsByID[0].docs_cvi_value || "",
            docs_cvi_amount: parcelDetailsByID[0].doc_cvi_amount || "",
            docs_detail: parcelDetailsByID[0].docs_detail || "",

            pay_method: parcelDetailsByID[0].payment_method || "",
          }))
          setPrint(true)
          setEditID(id)
          setTrackID(parcelDetailsByID[0].track_id || "")
          setPrintClicked(true)
          if (parcelDetailsByID[0].service_name === "Docs. Attestation") {
            setDocumentMode(true)
          } else {
            setDocumentMode(false)
          }
        } else {
          console.error("No data found")
        }
      } else {
        console.error("Failed to fetch users:", response.data.message)
      }
    } catch (error) {
      console.error("Error fetching users:", error)
    }
  }

  const fetchParcelinfo = status => {
    const data = {
      id: status,
      Role: role,
      user_id: user_id,
    }
    const URL = `${apiURl}/fetchParcelByStatus`
    axios
      .post(URL, data)

      .then(response => {
        if (response.data.status === "success") {
          setOrders(response.data.parcelDetails)
        } else {
          console.error("Failed to fetch users:", response.data.message)
        }
      })
      .catch(error => {
        console.error("Error fetching users:", error)
      })
  }

  useEffect(() => {
    fetchParcelinfo(id)
  }, [id])

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  })

  // Table Data

  const columns = useMemo(
    () => [
      {
        Header: "Parcel ID",
        accessor: "courier_id",
        disableFilters: true,
        Cell: cellProps => {
          return <>{cellProps.value}</>
        },
      },
      {
        Header: "ECS Track ID",
        accessor: "track_id",
        disableFilters: true,
        Cell: cellProps => {
          return <Type {...cellProps} />
        },
      },
      {
        Header: "Service",
        accessor: "service_name",
        disableFilters: true,
        Cell: cellProps => {
          return <Coin {...cellProps} />
        },
      },
      {
        Header: "Status",
        accessor: "status_",
        disableFilters: true,
        Cell: cellProps => {
          switch (cellProps.value) {
            case "new":
              return <Badge className="bg-info font-size-12">New</Badge>
            case "cancel":
              return <Badge className="bg-danger font-size-12">Cancel</Badge>
            case "in-progress":
              return (
                <Badge className="bg-warning font-size-12">In Progress</Badge>
              )
            case "delivered":
              return <Badge className="bg-success font-size-12">Deliverd</Badge>
          }
        },
      },
      {
        Header: "Assign Status",
        accessor: "assign_status",
        disableFilters: true,
        Cell: cellProps => {
          switch (cellProps.value) {
            case "NotAssign":
              return (
                <Badge className="bg-danger font-size-10">Not Assigned</Badge>
              )
            case "assigned":
              return <Badge className="bg-success font-size-10">Assigned</Badge>
          }
        },
      },
      {
        Header: "Booking Date",
        accessor: "created_date",
        disableFilters: true,
        Cell: cellProps => {
          return <Coin {...cellProps} />
        },
      },
      {
        Header: "Action",
        accessor: "id2",
        disableFilters: true,
        Cell: cellProps => {
          const id = cellProps.row.original.courier_id
          return (
            <>
              <div className="d-flex gap-2">
                <Link
                  onClick={() => {
                    ParceldataforPrint(id)
                  }}
                  className="text-primary"
                >
                  <i className="mdi mdi-printer font-size-18" id="print" />
                  <UncontrolledTooltip placement="top" target="print">
                    print
                  </UncontrolledTooltip>
                </Link>
                <Link
                  to={`/view/courier_detail/${id}`}
                  className="text-primary"
                >
                  <i className="mdi mdi-eye font-size-18" id="viewtooltip" />
                  <UncontrolledTooltip placement="top" target="viewtooltip">
                    view
                  </UncontrolledTooltip>
                </Link>
                <Link
                  onClick={() => {
                    deleteParcel(id)
                  }}
                  className="text-danger"
                >
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                  />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </Link>
              </div>
            </>
          )
        },
      },
    ],
    []
  )

  useEffect(() => {
    if (print && editID && printClicked) {
      handlePrint()
      setPrintClicked(false)
    }
  }, [print, editID, printClicked, handlePrint])

  //Delete Parcel
  const deleteParcel = id => {
    const data = {
      del_id: id,
      role: role,
    }
    const URL = `${apiURl}/deleteParcel`
    axios
      .post(URL, data)
      .then(response => {
        if (response.status === 200) {
          toastr.success("Parcel Delete Successfully")
          fetchParcelinfo(role, user_id)
        } else {
          console.error("Failed to fetch users:", response.data.message)
        }
      })
      .catch(error => {
        console.error("Error fetching users:", error)
      })
  }

  //Location
  const SEND = fdata.from_loca.substr(0, 3).toUpperCase()
  const REC = fdata.rec_loca.substr(0, 3).toUpperCase()

  //Parcel
  const Pcharges = parseFloat(fdata.ser_charges)
  let ParcelChargesWithoutCVi = 0
  let ParcelCviAmount = 0
  let ParcelGSTAmount = 0
  let TotalParCharges = 0

  if (fdata.parcel_cvi_status === "1") {
    ParcelCviAmount = parseFloat(fdata.parcel_price) * 0.025
    ParcelChargesWithoutCVi = Pcharges - ParcelCviAmount
    ParcelGSTAmount = ParcelChargesWithoutCVi * 0.15
    TotalParCharges =
      ParcelChargesWithoutCVi + ParcelCviAmount + ParcelGSTAmount
  } else {
    ParcelGSTAmount = Pcharges * 0.15
    TotalParCharges = Pcharges + ParcelGSTAmount
  }

  //Documents
  const Dcharges = parseFloat(fdata.Dser_caharges)
  let DocAmountWithountcvi
  let DocCviAmount = 0
  let DocgstAmount = 0
  let TotalDocCharges = 0

  if (fdata.docs_cvi_status === "1") {
    DocCviAmount = parseFloat(fdata.docs_cvi_amount) * 0.025
    DocAmountWithountcvi = Dcharges - DocCviAmount
    DocgstAmount = DocAmountWithountcvi * 0.15
    TotalDocCharges = DocAmountWithountcvi + DocCviAmount + DocgstAmount
  } else {
    DocgstAmount = Dcharges * 0.15
    TotalDocCharges = Dcharges + DocgstAmount
  }

  useEffect(() => {
    if (fdata.docs_cvi_status === "1" || fdata.parcel_cvi_status === "1") {
      setCvimode(true)
    } else {
      setCvimode(false)
    }
  }, [fdata.docs_cvi_status, fdata.parcel_cvi_status])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          {/* <Breadcrumbs title="Crypto" breadcrumbItem="Orders" /> */}
          <Row>
            <Col xl="6">
              <div>
                <h5 className="mt-2">{id.toUpperCase()} Couriers</h5>
              </div>
            </Col>
          </Row>

          <Row className="pt-3">
            <Col lg="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={orders}
                    // isGlobalFilter={true}
                    // isAddOptions={false}
                    customPageSize={10}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <div
          ref={printRef}
          className="printable"
          style={{
            display: "none",
            // margin: "0 auto" /* Center the content horizontally */,
            padding: "1mm" /* Add padding to the content */,
            // border: "1px solid #000",
            fontSize: "10px" /* Adjust font size as needed */,
            fontFamily: "Courier Prime, monospace",
          }}
        >
          <div className="text-center">
            <img src={profile} alt="" width={100} />
            <br />
            <QRCode
              value={`https://ecsinternational.com.pk/track_/${trackID}`}
              className="mt-2"
            />
            <br />
            <b>Tracking ID: </b> {fdata.ecs_track_id}
            <br />
            <strong style={{ marginTop: "-10px" }}>NTN:</strong> 7942338-0{" "}
          </div>
          {/* Receipt Details */}
          <div className="col-12 mt-2">
            <div
              className="text-center"
              style={{ backgroundColor: "black", color: "white" }}
            >
              <b>Payment</b>
            </div>
            <b>Method:</b> {fdata.pay_method}
            <div className="float-end">
              <b>Date: </b> {fdata.b_date}
            </div>
            <br />
          </div>
          <div className="col-12">
            <div
              className="text-center"
              style={{ backgroundColor: "black", color: "white" }}
            >
              <b>Detail's</b>
            </div>
            <Row>
              <Col xl="12">
                <b>ORG-DEST</b> [{SEND}-{REC}]
                <br />
                <b>SRV: </b> {fdata.service_name}
              </Col>
              <Col xl="12">
                <b>Staff</b>: {fdata.employee_id}
                <div className="float-end">
                  {DocumentMode ? <b>No. Docs: </b> : <></>}{" "}
                  {DocumentMode ? fdata.no_of_doc : <></>}
                </div>
              </Col>
            </Row>
          </div>
          <div className="col-12 ">
            <div
              className="text-center"
              style={{ backgroundColor: "black", color: "white" }}
            >
              {DocumentMode ? <b>Doc. Detail</b> : <b>Parcel Detail</b>}
            </div>
            <Row>
              <Col xl="12">
                <b>
                  {DocumentMode ? "Office Delivery: " : "Parcel Contents: "}
                </b>{" "}
                {DocumentMode
                  ? (() => {
                      switch (fdata.office_del) {
                        case "oto_":
                          return " OTO"
                        case "otd_":
                          return "OTD"
                        default:
                          return "Unknown"
                      }
                    })()
                  : fdata.content_name}
                <div className="float-end">
                  <b>
                    {DocumentMode ? "City Delivery: " : "Parcel Weight (kg): "}
                  </b>{" "}
                  {DocumentMode
                    ? (() => {
                        switch (fdata.city_del) {
                          case "wc_":
                            return "WC"
                          case "ctc_":
                            return "CTC"
                          default:
                            return "Unknown"
                        }
                      })()
                    : fdata.parcel_weight}
                </div>
              </Col>
              <Row>
                <Col xl={12}>
                  <div>
                    {""}
                    {DocumentMode ? (
                      <>
                        {" "}
                        <b>Detail :</b> {fdata.docs_detail}{" "}
                      </>
                    ) : (
                      <>
                        <b>No Of Contents:</b> {fdata.no_of_products}{" "}
                      </>
                    )}
                  </div>
                </Col>
              </Row>
            </Row>
          </div>
          <div className="col-12 ">
            <div
              className="text-center"
              style={{ backgroundColor: "black", color: "white" }}
            >
              <b>Sender Info</b>
            </div>
            <b>Name </b> {fdata.s_name}
            <br />
            <b>Ph No. </b> {fdata.s_phone}
            <br />
            <b>Address </b> {fdata.s_address}
          </div>
          <div className="col-12 ">
            <div
              className="text-center"
              style={{ backgroundColor: "black", color: "white" }}
            >
              <b>Receiver Info</b>
            </div>
            <b>Name </b> {fdata.r_name}
            <br />
            <b>Ph No. </b> {fdata.r_phone}
            <br />
            <b>Address </b> {fdata.r_address}
          </div>
          {/* Total Amount */}
          <div className="col-12 ">
            <div
              className="text-center"
              style={{ backgroundColor: "black", color: "white" }}
            >
              <b>Amount</b>
            </div>
            {cvimode ? (
              <>
                <b>Ser Charges:</b>
                <div className="float-end">
                  {DocumentMode
                    ? Number(DocAmountWithountcvi).toFixed(2)
                    : Number(ParcelChargesWithoutCVi).toFixed(2)}
                </div>
                <br />
              </>
            ) : (
              <>
                <b>Ser Charges:</b>{" "}
                <div className="float-end">
                  {DocumentMode
                    ? Number(Dcharges).toFixed(2)
                    : Number(Pcharges).toFixed(2)}
                </div>
                <br />
              </>
            )}
            {cvimode ? (
              <>
                <b>CVI (2.5%): </b>
                <div className="float-end">
                  {DocumentMode
                    ? Number(DocCviAmount).toFixed(2)
                    : Number(ParcelCviAmount).toFixed(2)}
                </div>
                <br />
                <b>GST (15%): </b>
                <div className="float-end">
                  {DocumentMode
                    ? Number(DocgstAmount).toFixed(2)
                    : Number(ParcelGSTAmount).toFixed(2)}
                </div>
              </>
            ) : (
              <>
                <b>GST (15%): </b>
                <div className="float-end">
                  {DocumentMode
                    ? Number(DocgstAmount).toFixed(2)
                    : Number(ParcelGSTAmount).toFixed(2)}
                </div>
              </>
            )}
            <br />
            <br />
            {cvimode ? (
              <>
                <b>Total: </b>{" "}
                <div className="float-end">
                  <b>
                    {DocumentMode
                      ? TotalDocCharges.toFixed(2)
                      : TotalParCharges.toFixed(2)}
                  </b>
                </div>
              </>
            ) : (
              <>
                <b>Total: </b>{" "}
                <div className="float-end">
                  <b>
                    {DocumentMode
                      ? TotalDocCharges.toFixed(2)
                      : TotalParCharges.toFixed(2)}
                  </b>
                </div>
              </>
            )}
          </div>
          <div style={{ borderTop: "1px solid gray", width: "100%" }}></div>
          <div className="text-center">
            For term's & conditions visit our site
            <br />
            ecs.com.pk
            {/* <br /> */}
            {/* Head Office No. 20/22 E,
            <br /> Mezzanine Floor, Saeed Plaza,
            <br /> Jinnah Avenue, Blue Area,
            <br /> Islamabad */}
            <br /> <strong>UAN</strong> : 051-111-327-327
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default AllCourierDetail
