import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Badge,
  Row,
  Table,
  Input,
  Button,
} from "reactstrap"
import { isEmpty, map } from "lodash"
//Import Breadcrumb
// import Breadcrumbs from "../../components/Common/Breadcrumb"

//Import Image
// import logo from "../../assets/images/Pham logo.png"
// import logo from "../../assets/images/ecs.png"
import logo from "../../assets/images/ecs.png"
// import logoLight from "../../assets/images/logo-light.png"
// import { getCourierDetail as onGetCourierDetail } from "../../store/invoices/actions"
//redux
import { useSelector, useDispatch } from "react-redux"
import axios from "axios"

import toastr from "toastr"
import "toastr/build/toastr.min.css"
import { use } from "i18next"
import apiURl from "url"

const TrackCourier = props => {
  //meta title
  document.title = "ECS | Track Courier"
  const { id } = useParams()
  const TID = id

  const printInvoice = () => {
    window.print()
  }

  const navigate = useNavigate()

  const [Parcelinfo, SetParcelinfo] = useState([])
  const [orderNo, setOrderNo] = useState([])
  const [sname, setSname] = useState(null)
  const [sphone, setSphone] = useState(null)
  const [saddress, setSaddress] = useState(null)
  const [rname, setRname] = useState(null)
  const [rphone, setRphone] = useState(null)
  const [raddress, setRaddress] = useState(null)

  const [status, setStatus] = useState([])
  const [Edit, SetEdit] = useState(false)
  const [cahngedstatus, setChangedstatus] = useState([])

  const [service, setService] = useState(null)
  const [DocumentMode, setDocumentMode] = useState(false)

  const [documentDetail, setDocumentDetail] = useState(null)

  const [employee, setEmployee] = useState(null)

  const fetchParcelinfoByID = () => {
    const URL =
      `${apiURl}/fetchParcelDetailsByTrackID`
    const id = { id: TID } // Wrap the id in an object
    axios
      .post(URL, id)
      .then(response => {
        if (response.data.status === "success") {
          setSname(response.data.parcelDetailsByID[0].sender_full_name)
          setSphone(response.data.parcelDetailsByID[0].sender_phone)
          setSaddress(response.data.parcelDetailsByID[0].sender_address)
          setRname(response.data.parcelDetailsByID[0].receiver_full_name)
          setRphone(response.data.parcelDetailsByID[0].receiver_phone)
          setRaddress(response.data.parcelDetailsByID[0].receiver_address)
          SetParcelinfo(response.data.parcelDetailsByID)
          setDocumentDetail(response.data.parcelDetailsByID[0].docs_detail)
          //Status
          setStatus(response.data.parcelDetailsByID[0].status_)
          //Service Name
          setService(response.data.parcelDetailsByID[0].service_name)
          setEmployee(response.data.parcelDetailsByID[0].employee_name)
          if (
            response.data.parcelDetailsByID[0].service_name ===
            "Docs. Attestation"
          ) {
            setDocumentMode(true)
          } else {
            setDocumentMode(false)
          }
        } else {
          console.error("Failed to fetch users:", response.data.message)
        }
      })
      .catch(error => {
        console.error("Error fetching users:", error)
      })
  }

  useEffect(() => {
    fetchParcelinfoByID()
  }, [])

  const handleEdit = id => {
    navigate(`/edit_courier_detail/${id}`)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          {/* <Breadcrumbs title="Invoices" breadcrumbItem="Invoice Detail" /> */}

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="invoice-title">
                    <div className="mb-2 text-center">
                      <h4 className="font-size-16 mt-2">
                        <strong>Track ID: </strong> {TID}
                      </h4>
                    </div>
                  </div>
                  <hr />

                  <Row>
                    <Col sm="6" className="mt-3">
                      <CardTitle style={{ color: "#556EE6" }}>
                        Supplier info
                      </CardTitle>
                      <br />
                      <div className="mb-2">
                        <address>
                          <strong>Name : </strong> {sname}
                        </address>
                      </div>
                      <div className="mb-2">
                        <address>
                          <strong> Phone : </strong>
                          {sphone}
                        </address>
                      </div>
                      <div className="mb-2">
                        <address>
                          <strong> Address </strong> <br /> {saddress}
                        </address>
                      </div>
                    </Col>
                    <Col sm="6" className="mt-3 text-sm">
                      <div className="float-end">
                        <CardTitle style={{ color: "#556EE6" }}>
                          Receiver info
                        </CardTitle>
                        <br />
                        <div className="mb-2">
                          <address>
                            <strong>Name : </strong> {rname}
                          </address>
                        </div>
                        <div className="mb-2">
                          <address>
                            <strong> Phone : </strong> {rphone}
                          </address>
                        </div>
                        <div className="mb-2">
                          <address>
                            <strong> Address </strong> <br />
                            {raddress}
                          </address>
                        </div>
                        <br />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    {DocumentMode && (
                      <Col sm="12" className="mt2">
                        <div className="float-end">
                          <strong>Document Datail</strong>
                          <Input
                            type="textarea"
                            id="document_detail"
                            rows="2"
                            placeholder=""
                            name="document_detail"
                            value={documentDetail}
                            // onChange={handleParcel}
                            disabled
                          />
                        </div>
                      </Col>
                    )}
                  </Row>

                  {DocumentMode ? (
                    <>
                      <div className="py-2 mt-3">
                        <h3 className="font-size-15 fw-bold">
                          Documents summary
                        </h3>
                      </div>
                      <div className="table-responsive">
                        <Table
                          className="table-nowrap"
                          style={{ border: "1px solid #63719c" }}
                        >
                          <thead style={{ backgroundColor: "#F5F5F9" }}>
                            <tr>
                              <th>Track ID</th>
                              <th>Docs Deliver</th>
                              <th>Docs City Option</th>
                              <th>No. Of Docs</th>
                              <th>Charges(Rs)</th>
                              <th>CVI</th>
                              <th>Assign Status</th>
                              <th>Courier Status</th>
                              <th>Booking Data</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Parcelinfo.length > 0 ? (
                              Parcelinfo.filter(e => !!e).map((e, index) => (
                                <tr key={index}>
                                  <td>{e.track_id}</td>
                                  <td>
                                    {(() => {
                                      switch (e.docs_office_type) {
                                        case "oto_":
                                          return " Office To Office"
                                        case "otd_":
                                          return "Office To Door"
                                        default:
                                          return "Unknown"
                                      }
                                    })()}
                                  </td>
                                  <td>
                                    {(() => {
                                      switch (e.docs_city_type) {
                                        case "wc_":
                                          return "Within City"
                                        case "ctc_":
                                          return "City To City"
                                        default:
                                          return "Unknown"
                                      }
                                    })()}
                                  </td>
                                  <td>{e.no_of_docs}</td>

                                  <td>
                                    {Number(e.docs_total_price).toFixed(2)}
                                  </td>
                                  <td>
                                    {(() => {
                                      switch (e.docs_cvi_value) {
                                        case "1":
                                          return "Done"
                                        case "0":
                                          return "None"
                                        default:
                                          return "Unknown"
                                      }
                                    })()}
                                  </td>
                                  <td>
                                    {(() => {
                                      switch (e.assign_status) {
                                        case "NotAssign":
                                          return (
                                            <Badge className="bg-danger font-size-10">
                                              Not Assigned
                                            </Badge>
                                          )
                                        case "assigned":
                                          return (
                                            <Badge className="bg-success font-size-10">
                                              Assigned
                                            </Badge>
                                          )
                                        default:
                                          return "Unknown"
                                      }
                                    })()}
                                  </td>

                                  <td>
                                    {(() => {
                                      switch (e.status_) {
                                        case "new":
                                          return (
                                            <Badge className="bg-info font-size-12">
                                              New
                                            </Badge>
                                          )
                                        case "cancel":
                                          return (
                                            <Badge className="bg-danger font-size-12">
                                              Cancel
                                            </Badge>
                                          )
                                        case "in-progress":
                                          return (
                                            <Badge className="bg-warning font-size-12">
                                              In Progress
                                            </Badge>
                                          )
                                        case "delivered":
                                          return (
                                            <Badge className="bg-success font-size-12">
                                              Deliverd
                                            </Badge>
                                          )
                                      }
                                    })()}
                                  </td>
                                  <td>{e.created_date}</td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td>No Data Found</td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="py-2 mt-3">
                        <h3 className="font-size-15 fw-bold">Parcel summary</h3>
                      </div>
                      <div className="table-responsive">
                        <Table
                          className="table-nowrap"
                          style={{ border: "1px solid #63719c" }}
                        >
                          <thead style={{ backgroundColor: "#F5F5F9" }}>
                            <tr>
                              <th>Track ID</th>
                              <th>No. Of Content</th>
                              <th>Content Weight</th>
                              <th>Content Value</th>
                              <th>CVI</th>
                              <th>Assign Status</th>
                              <th>Courier Status</th>
                              <th>Booking Data</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Parcelinfo.length > 0 ? (
                              Parcelinfo.filter(e => !!e).map((e, index) => (
                                <tr key={index}>
                                  <td>{e.track_id}</td>
                                  <td>{e.no_of_products}</td>
                                  <td>{e.parcel_weight} (Kg)</td>
                                  <td>
                                    {(() => {
                                      switch (e.parcel_price) {
                                        case "":
                                          return "0.00"
                                        case "0":
                                          return "0.00"
                                        default:
                                          return e.parcel_price
                                      }
                                    })()}
                                  </td>
                                  <td>
                                    {(() => {
                                      switch (e.parcel_cvi_value) {
                                        case "1":
                                          return "Done"
                                        case "0":
                                          return "None"
                                        default:
                                          return "Unknown"
                                      }
                                    })()}
                                  </td>
                                  <td>
                                    {(() => {
                                      switch (e.assign_status) {
                                        case "NotAssign":
                                          return (
                                            <Badge className="bg-danger font-size-10">
                                              Not Assigned
                                            </Badge>
                                          )
                                        case "assigned":
                                          return (
                                            <Badge className="bg-success font-size-10">
                                              Assigned
                                            </Badge>
                                          )
                                        default:
                                          return "Unknown"
                                      }
                                    })()}
                                  </td>
                                  <td>
                                    {(() => {
                                      switch (e.status_) {
                                        case "new":
                                          return (
                                            <Badge className="bg-info font-size-12">
                                              New
                                            </Badge>
                                          )
                                        case "in-progress":
                                          return (
                                            <Badge className="bg-warning font-size-12">
                                              In Progress
                                            </Badge>
                                          )
                                        case "cancel":
                                          return (
                                            <Badge className="bg-danger font-size-12">
                                              Cancel
                                            </Badge>
                                          )
                                        case "delivered":
                                          return (
                                            <Badge className="bg-success font-size-12">
                                              Deliverd
                                            </Badge>
                                          )
                                      }
                                    })()}
                                  </td>
                                  <td>{e.created_date}</td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td>No Data Found</td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </div>
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

TrackCourier.propTypes = {
  match: PropTypes.any,
}

export default withRouter(TrackCourier)
