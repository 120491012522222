import React, { useEffect, useState, useMemo, useRef } from "react"
import { useReactToPrint } from "react-to-print"
import QRCode from "qrcode.react"

import PropTypes from "prop-types"
import { Link, useNavigate } from "react-router-dom"

import "../../assets/style/style.css"
import DeleteModal from "components/Common/DeleteModal"

import {
  Card,
  CardBody,
  CardTitle,
  InputGroup,
  Button,
  Col,
  Container,
  UncontrolledTooltip,
  Badge,
  Row,
  Label,
} from "reactstrap"

import { Type, Coin } from "../EcsUsers/userstabledata"

import TableContainer from "../../components/Common/TableContainer"

// import img2 from "../../assets/images/no-time-off.59f54e64.svg"

//Date Picker
import "react-datepicker/dist/react-datepicker.css"
import "assets/scss/datatables.scss"

// import { cryptoOrderData } from "../../../common/data";

//Import Breadcrumb

import toastr from "toastr"
import "toastr/build/toastr.min.css"
import axios from "axios"
import profile from "assets/images/ecs234.jpg"
import { use } from "i18next"
import Select from "react-select"
import apiURl from "url"
import excelIcon from "../../../src/assets/images/excel.svg"
import LeadsDownload from "components/Common/Excel"
import PdfLeadsDownload from "components/Common/Pdf"
function formatAmountCurrency(amount, currency) {
  return amount.toLocaleString("en-US", {
    style: "currency",
    currency: currency,
    minimumFractionDigits: 2,
  })
}

const AllCourierDetail = props => {
  //meta title
  document.title = "All Courier"
  const navigate = useNavigate()

  const role = localStorage.getItem("role")
  const user_id = localStorage.getItem("login_user_id")

  const printRef = useRef()
  const [todayDate, setTodayDate] = useState(null)

  //Table Data
  const [orders, setOrders] = useState([])
  const [loading, setLoading] = useState(false)
  //Print Data

  const [DocumentMode, setDocumentMode] = useState(false)
  const [editID, setEditID] = useState(null)
  const [print, setPrint] = useState(false)
  const [printClicked, setPrintClicked] = useState(false) // Flag to track print button click
  const [cvimode, setCvimode] = useState(false)
  const [trackID, setTrackID] = useState("")
  const [TotalRevenue, setTotalRevenue] = useState("")
  const [fdata, setFdata] = useState({
    s_name: "",
    s_phone: "",
    s_address: "",

    r_name: "",
    r_phone: "",
    r_address: "",

    staus: "",

    b_date: "",

    service_name: "",
    employee_name: "",
    employee_id: "",

    ecs_track_id: "",

    ser_charges: "",
    Dser_caharges: "",

    content_name: "",
    parcel_price: "",
    parcel_weight: "",
    parcel_cvi_status: "",
    no_of_products: "",

    from_loca: "",
    rec_loca: "",

    no_of_doc: "",
    office_del: "",
    city_del: "",
    docs_cvi_status: "",
    docs_detail: "",
    docs_cvi_amount: "",

    pay_method: "",
  })

  //Fetch Print Data

  const ParceldataforPrint = async id => {
    try {
      const URL = `${apiURl}/fetchParcelDetailsByID`
      const response = await axios.post(URL, { id })
      if (response.data.status === "success") {
        const parcelDetailsByID = response.data.parcelDetailsByID
        const value = parcelDetailsByID.map(e => e.docs_cvi_value)
        setCvimode(value[0] == "1" ? true : false)
        if (parcelDetailsByID.length > 0) {
          setFdata(prevData => ({
            ...prevData,
            s_name: parcelDetailsByID[0].sender_full_name || "",
            s_phone: parcelDetailsByID[0].sender_phone || "",
            s_address: parcelDetailsByID[0].sender_address || "",

            r_name: parcelDetailsByID[0].receiver_full_name || "",
            r_phone: parcelDetailsByID[0].receiver_phone || "",
            r_address: parcelDetailsByID[0].receiver_address || "",

            staus: parcelDetailsByID[0].status_ || "",

            b_date: parcelDetailsByID[0].created_date || "",

            service_name: parcelDetailsByID[0].service_name || "",
            employee_name: parcelDetailsByID[0].employee_name || "",
            employee_id: parcelDetailsByID[0].employee_id || "",

            ecs_track_id: parcelDetailsByID[0].track_id || "",

            ser_charges: parcelDetailsByID[0].total_price || "",
            Dser_caharges: parcelDetailsByID[0].docs_total_price || "",

            from_loca: parcelDetailsByID[0].send_location || "",
            rec_loca: parcelDetailsByID[0].receive_location || "",

            content_name: parcelDetailsByID[0].parcel_info || "",
            parcel_price: parcelDetailsByID[0].parcel_price || "",
            parcel_weight: parcelDetailsByID[0].parcel_weight || "",
            parcel_cvi_status: parcelDetailsByID[0].parcel_cvi_value || "",
            no_of_products: parcelDetailsByID[0].no_of_products || "",

            no_of_doc: parcelDetailsByID[0].no_of_docs || "",
            office_del: parcelDetailsByID[0].docs_office_type || "",
            city_del: parcelDetailsByID[0].docs_city_type || "",
            docs_cvi_status: parcelDetailsByID[0].docs_cvi_value || "",
            docs_cvi_amount: parcelDetailsByID[0].doc_cvi_amount || "",
            docs_detail: parcelDetailsByID[0].docs_detail || "",

            pay_method: parcelDetailsByID[0].payment_method || "",
          }))
          setPrint(true)
          setEditID(id)
          setTrackID(parcelDetailsByID[0].track_id || "")
          setPrintClicked(true)
          if (parcelDetailsByID[0].service_name === "Docs. Attestation") {
            setDocumentMode(true)
          } else {
            setDocumentMode(false)
          }
        } else {
          console.error("No data found")
        }
      } else {
        console.error("Failed to fetch users:", response.data.message)
      }
    } catch (error) {
      console.error("Error fetching users:", error)
    }
  }

  const fetchParcelinfo = (Role, User_id) => {
    setOrders([])
    setLoading(true)
    const data = {
      role: Role,
      user_id: User_id,
    }
    const URL = `${apiURl}/fetchParcelDetails`
    axios
      .post(URL, data)
      .then(response => {
        if (response.data.status === "success") {
          const Data = response.data.parcelDetails
          setOrders(Data)
          setLoading(false)
          let TotalParcelAmount = 0
          let TotalDocAmount = 0
          let ParcelCviAmount = 0
          let DocCviAmount = 0

          Data.forEach(item => {
            const Parcelamount = parseFloat(item.total_price) || 0
            const Docamount = parseFloat(item.docs_total_price) || 0
            const Pcviamount = parseFloat(item.parcel_price) || 0
            const Dcviamount = parseFloat(item.doc_cvi_amount) || 0

            TotalParcelAmount += Parcelamount
            TotalDocAmount += Docamount
            ParcelCviAmount += Pcviamount
            DocCviAmount += Dcviamount
          })
          const TotalAmount = TotalParcelAmount + TotalDocAmount
          const TotalCviAmount = ParcelCviAmount + DocCviAmount
          const CviPercent = TotalCviAmount * 0.025
          const ForGST = TotalAmount - CviPercent
          const GSTAmount = ForGST * 0.15
          const TotalAmountWithGST = TotalAmount + GSTAmount
          setTotalRevenue(TotalAmountWithGST)
        } else {
          console.error("Failed to fetch users:", response.data.message)
          setLoading(true)
        }
      })
      .catch(error => {
        console.error("Error fetching users:", error)
        setLoading(true)
      })
  }

  useEffect(() => {
    fetchParcelinfo(role, user_id)
  }, [role, user_id])

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  })

  // Table Data

  const columns = useMemo(
    () => [
      {
        Header: "No.",
        accessor: "courier_id",
        disableFilters: true,

        Cell: cellProps => {
          return <>{cellProps.row.index + 1}</>
        },
      },
      {
        Header: "ECS Track ID",
        accessor: "track_id",
        disableFilters: true,
        Cell: cellProps => {
          return <Type {...cellProps} />
        },
      },
      {
        Header: "Book City",
        accessor: "book_city",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <>
              {cellProps.value
                ? cellProps.value.charAt(0).toUpperCase() +
                  cellProps.value.slice(1)
                : ""}
            </>
          )
        },
      },
      {
        Header: "Service",
        accessor: "service_name",
        disableFilters: true,
        Cell: cellProps => {
          return <Coin {...cellProps} />
        },
      },
      {
        Header: "Status",
        accessor: "status_",
        disableFilters: true,
        Cell: cellProps => {
          switch (cellProps.value) {
            case "new":
              return <Badge className="bg-info font-size-12">New</Badge>
            case "cancel":
              return <Badge className="bg-danger font-size-12">Cancel</Badge>
            case "in-progress":
              return (
                <Badge className="bg-warning font-size-12">In Progress</Badge>
              )
            case "dispatch":
              return <Badge className="bg-warning font-size-12">Dispatch</Badge>
            case "delivered":
              return <Badge className="bg-success font-size-12">Deliverd</Badge>
          }
        },
      },
      {
        Header: "Assign Status",
        accessor: "assign_status",
        disableFilters: true,
        Cell: cellProps => {
          switch (cellProps.value) {
            case "NotAssign":
              return (
                <Badge className="bg-danger font-size-10">Not Assigned</Badge>
              )
            case "assigned":
              return <Badge className="bg-success font-size-10">Assigned</Badge>
          }
        },
      },
      {
        Header: "Total Amount",
        accessor: "amount",
        disableFilters: true,
        Cell: cellProps => {
          const parcelAmount = parseFloat(cellProps.row.original.total_price)
          const documentAmount = parseFloat(
            cellProps.row.original.docs_total_price
          )

          const PCVIPERCENT =
            parseFloat(cellProps.row.original.parcel_price) * 0.025
          const DCVIPERCENT =
            parseFloat(cellProps.row.original.doc_cvi_amount) * 0.025

          const PA_FOR_GST = (parcelAmount - PCVIPERCENT) * 0.15 + parcelAmount
          const DA_FOR_GST =
            (documentAmount - DCVIPERCENT) * 0.15 + documentAmount

          const service = cellProps.row.original.service_name
          return service === "Docs. Attestation"
            ? formatAmountCurrency(parseFloat(DA_FOR_GST), "PKR")
            : formatAmountCurrency(parseFloat(PA_FOR_GST), "PKR")
        },
      },
      {
        Header: "Booking Date",
        accessor: "created_date",
        disableFilters: true,
        Cell: cellProps => {
          return <Coin {...cellProps} />
        },
      },
      {
        Header: "Action",
        accessor: "id2",
        disableFilters: true,
        Cell: cellProps => {
          const id = cellProps.row.original.courier_id
          return (
            <>
              <div className="d-flex gap-2">
                <Link
                  onClick={() => {
                    ParceldataforPrint(id)
                  }}
                  className="text-primary"
                >
                  <i className="mdi mdi-printer font-size-18" id="print" />
                  <UncontrolledTooltip placement="top" target="print">
                    print
                  </UncontrolledTooltip>
                </Link>
                <Link
                  to={`/view/courier_detail/${id}`}
                  className="text-primary"
                >
                  <i className="mdi mdi-eye font-size-18" id="viewtooltip" />
                  <UncontrolledTooltip placement="top" target="viewtooltip">
                    view
                  </UncontrolledTooltip>
                </Link>
                <Link
                  onClick={() => {
                    deleteParcel(id)
                  }}
                  className="text-danger"
                >
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                  />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </Link>
              </div>
            </>
          )
        },
      },
    ],
    []
  )

  useEffect(() => {
    if (print && editID && printClicked) {
      handlePrint()
      setPrintClicked(false)
    }
  }, [print, editID, printClicked, handlePrint])

  //Location
  const SEND = fdata.from_loca.substr(0, 3).toUpperCase()
  const REC = fdata.rec_loca.substr(0, 3).toUpperCase()

  //Parcel
  const Pcharges = parseFloat(fdata.ser_charges)
  let ParcelChargesWithoutCVi = 0
  let ParcelCviAmount = 0
  let ParcelGSTAmount = 0
  let TotalParCharges = 0

  if (fdata.parcel_cvi_status === "1") {
    ParcelCviAmount = parseFloat(fdata.parcel_price) * 0.025
    ParcelChargesWithoutCVi = Pcharges - ParcelCviAmount
    ParcelGSTAmount = ParcelChargesWithoutCVi * 0.15
    TotalParCharges =
      ParcelChargesWithoutCVi + ParcelCviAmount + ParcelGSTAmount
  } else {
    ParcelGSTAmount = Pcharges * 0.15
    TotalParCharges = Pcharges + ParcelGSTAmount
  }

  //Documents
  const Dcharges = parseFloat(fdata.Dser_caharges)
  let DocAmountWithountcvi
  let DocCviAmount = 0
  let DocGst = 0
  let DocgstAmount = 0
  let TotalDocCharges = 0

  if (fdata.docs_cvi_status === "1") {
    DocCviAmount = parseFloat(fdata.docs_cvi_amount) * 0.025
    DocAmountWithountcvi = Dcharges - DocCviAmount
    DocgstAmount = DocAmountWithountcvi * 0.15
    TotalDocCharges = DocAmountWithountcvi + DocCviAmount + DocgstAmount
  } else {
    DocgstAmount = Dcharges * 0.15
    TotalDocCharges = Dcharges + DocgstAmount
  }

  useEffect(() => {
    if (fdata.docs_cvi_status === "1" || fdata.parcel_cvi_status === "1") {
      setCvimode(true)
    } else {
      setCvimode(false)
    }
  }, [fdata.docs_cvi_status, fdata.parcel_cvi_status])

  //Delete Modal
  const [deleteModal, setDeleteModal] = useState(false)
  const [del_id, setDel_id] = useState([])
  const deleteParcel = id => {
    setDel_id(id)
    setDeleteModal(true)
  }

  //Delete Parcel
  const handleDeleteCourier = () => {
    const data = {
      del_id: del_id,
      role: role,
    }
    const URL = `${apiURl}/deleteParcel`
    axios
      .post(URL, data)
      .then(response => {
        if (response.status === 200) {
          fetchParcelinfo(role, user_id)
          setDeleteModal(false)
          toastr.success("Parcel Delete Successfully")
        } else {
          setDeleteModal(false)
          toastr.error("Failed to Delete:", response.data.message)
        }
      })
      .catch(error => {
        toastr.error("Failed to Delete", error)
      })
  }

  //
  const [status, setStatus] = useState(null)
  const [selectedUser, setSelectedUser] = useState(null)
  const [selectedUserCity, setSelectedUserCity] = useState(null)

  //Handle User
  const handleUser = selectedOption => {
    if (selectedOption) {
      setSelectedUser(selectedOption.value)
    } else {
      setSelectedUser(null)
    }
  }
  //Handle City
  const handleCity = selectedOption => {
    if (selectedOption) {
      setSelectedUserCity(selectedOption.value)
    } else {
      setSelectedUserCity(null)
    }
  }

  //All User
  const [fetchUsers2, setFetchUsers2] = useState([])
  const [usersCity, setUsersCity] = useState([])
  const fetchUsers = () => {
    setUsersCity([])
    const URL = `${apiURl}/fetchUsers`
    axios
      .get(URL)
      .then(response => {
        if (response.data.status === "success") {
          const Data = response.data.users
          const uniqueCities = new Set(
            Data.map(user => user.city_.trim().toUpperCase())
          )
          setFetchUsers2(Data)
          setUsersCity(Array.from(uniqueCities))
        } else {
          console.error("Failed to fetch users:", response.data.message)
        }
      })
      .catch(error => {
        console.error("Error fetching users:", error)
      })
  }
  useEffect(() => {
    fetchUsers()
  }, [])

  //Filter
  const handleFilter = () => {
    const data = {
      role: role,
      user_id: user_id,
    }
    const URL = `${apiURl}/fetchParcelDetails`
    axios
      .post(URL, data)
      .then(response => {
        if (response.data.status === "success") {
          let filter = response.data.parcelDetails

          if (
            selectedUserCity !== null &&
            selectedUser !== null &&
            status !== null &&
            todayDate !== null
          ) {
            // Filter by selectedUserCity, selectedUser, status, and today's date
            filter = response.data.parcelDetails.filter(
              item =>
                item.book_city.trim().toUpperCase() === selectedUserCity &&
                item.employee_id === selectedUser &&
                item.status_ === status &&
                item.created_date === todayDate
            )
          } else if (
            selectedUserCity !== null &&
            selectedUser !== null &&
            status !== null &&
            todayDate === null
          ) {
            // Filter by selectedUserCity, selectedUser, and status
            filter = response.data.parcelDetails.filter(
              item =>
                item.book_city.trim().toUpperCase() === selectedUserCity &&
                item.employee_id === selectedUser &&
                item.status_ === status
            )
          } else if (
            selectedUserCity !== null &&
            selectedUser !== null &&
            status === null &&
            todayDate !== null
          ) {
            // Filter by selectedUserCity, selectedUser, and today's date
            filter = response.data.parcelDetails.filter(
              item =>
                item.book_city.trim().toUpperCase() === selectedUserCity &&
                item.employee_id === selectedUser &&
                item.created_date === todayDate
            )
          } else if (
            selectedUserCity !== null &&
            selectedUser !== null &&
            status === null &&
            todayDate === null
          ) {
            // Filter by selectedUserCity and selectedUser
            filter = response.data.parcelDetails.filter(
              item =>
                item.book_city.trim().toUpperCase() === selectedUserCity &&
                item.employee_id === selectedUser
            )
          } else if (
            selectedUserCity !== null &&
            selectedUser === null &&
            status !== null &&
            todayDate !== null
          ) {
            // Filter by selectedUserCity, status, and today's date
            filter = response.data.parcelDetails.filter(
              item =>
                item.book_city.trim().toUpperCase() === selectedUserCity &&
                item.status_ === status &&
                item.created_date === todayDate
            )
          } else if (
            selectedUserCity !== null &&
            selectedUser === null &&
            status !== null &&
            todayDate === null
          ) {
            // Filter by selectedUserCity and status
            filter = response.data.parcelDetails.filter(
              item =>
                item.book_city.trim().toUpperCase() === selectedUserCity &&
                item.status_ === status
            )
          } else if (
            selectedUserCity !== null &&
            selectedUser === null &&
            status === null &&
            todayDate !== null
          ) {
            // Filter by selectedUserCity and today's date
            filter = response.data.parcelDetails.filter(
              item =>
                item.book_city.trim().toUpperCase() === selectedUserCity &&
                item.created_date === todayDate
            )
          } else if (
            selectedUserCity !== null &&
            selectedUser === null &&
            status === null &&
            todayDate === null
          ) {
            // Filter by selectedUserCity only
            filter = response.data.parcelDetails.filter(
              item => item.book_city.trim().toUpperCase() === selectedUserCity
            )
          } else if (
            selectedUserCity === null &&
            selectedUser !== null &&
            status !== null &&
            todayDate !== null
          ) {
            // Filter by selectedUser, status, and today's date
            filter = response.data.parcelDetails.filter(
              item =>
                item.employee_id === selectedUser &&
                item.status_ === status &&
                item.created_date === todayDate
            )
          } else if (
            selectedUserCity === null &&
            selectedUser !== null &&
            status !== null &&
            todayDate === null
          ) {
            // Filter by selectedUser and status
            filter = response.data.parcelDetails.filter(
              item =>
                item.employee_id === selectedUser && item.status_ === status
            )
          } else if (
            selectedUserCity === null &&
            selectedUser !== null &&
            status === null &&
            todayDate !== null
          ) {
            // Filter by selectedUser and today's date
            filter = response.data.parcelDetails.filter(
              item =>
                item.employee_id === selectedUser &&
                item.created_date === todayDate
            )
          } else if (
            selectedUserCity === null &&
            selectedUser !== null &&
            status === null &&
            todayDate === null
          ) {
            // Filter by selectedUser only
            filter = response.data.parcelDetails.filter(
              item => item.employee_id === selectedUser
            )
          } else if (
            selectedUserCity === null &&
            selectedUser === null &&
            status !== null &&
            todayDate !== null
          ) {
            // Filter by status and today's date
            filter = response.data.parcelDetails.filter(
              item => item.status_ === status && item.created_date === todayDate
            )
          } else if (
            selectedUserCity === null &&
            selectedUser === null &&
            status !== null &&
            todayDate === null
          ) {
            // Filter by status only
            filter = response.data.parcelDetails.filter(
              item => item.status_ === status
            )
          } else if (
            selectedUserCity === null &&
            selectedUser === null &&
            status === null &&
            todayDate !== null
          ) {
            // Filter by today's date only
            filter = response.data.parcelDetails.filter(
              item => item.created_date === todayDate
            )
          } else {
            // No filter applied
            filter = response.data.parcelDetails
          }
          let TotalParcelAmount = 0
          let TotalDocAmount = 0
          let ParcelCviAmount = 0
          let DocCviAmount = 0

          filter.forEach(item => {
            const Parcelamount = parseFloat(item.total_price) || 0
            const Docamount = parseFloat(item.docs_total_price) || 0
            const Pcviamount = parseFloat(item.parcel_price) || 0
            const Dcviamount = parseFloat(item.doc_cvi_amount) || 0

            TotalParcelAmount += Parcelamount
            TotalDocAmount += Docamount
            ParcelCviAmount += Pcviamount
            DocCviAmount += Dcviamount
          })
          const TotalAmount = TotalParcelAmount + TotalDocAmount
          const TotalCviAmount = ParcelCviAmount + DocCviAmount
          const CviPercent = TotalCviAmount * 0.025
          const ForGST = TotalAmount - CviPercent
          const GSTAmount = ForGST * 0.15
          const TotalAmountWithGST = TotalAmount + GSTAmount
          setTotalRevenue(TotalAmountWithGST)

          setOrders(filter)
        } else {
          console.error("Failed to fetch users:", response.data.message)
        }
      })
      .catch(error => {
        console.error("Error fetching users:", error)
      })
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteCourier}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          {/* <Breadcrumbs title="Crypto" breadcrumbItem="Orders" /> */}
          <Row className="mb-3">
            <Col xl="6">
              <div>
                <h5 className="mt-2">All Couriers</h5>
              </div>
            </Col>
          </Row>
          {role === "admin" && (
            <Row>
              <Col md="3">
                <Card className="mini-stats-wid">
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <p className="text-muted fw-medium">Revenue</p>
                        <h4 className="mb-0">
                          {TotalRevenue
                            ? formatAmountCurrency(TotalRevenue, "PKR")
                            : "0"}
                        </h4>
                      </div>
                      <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                        <span className="avatar-title rounded-circle bg-primary">
                          <i className={"bx bx-archive-in font-size-24"}></i>
                        </span>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}

          {role === "admin" && (
            <Card>
              <CardBody>
                <Row className="mb-3">
                  <Col xl={3}>
                    <div className="col-sm-auto">
                      <Label
                        className="control-label"
                        htmlFor="selectedUserCity"
                      >
                        City
                      </Label>
                      <Select
                        classNamePrefix="select2-selection"
                        placeholder={
                          selectedUserCity
                            ? selectedUserCity
                            : "Select City ..."
                        }
                        name="selectedUserCity"
                        onChange={handleCity}
                        options={[
                          ...(usersCity.length > 0
                            ? usersCity.map(city => ({
                                value: city,
                                label: city,
                              }))
                            : [{ value: "", label: "No data Found" }]),
                        ]}
                      />
                    </div>
                  </Col>
                  <Col xl={3}>
                    <div className="col-sm-auto">
                      <Label className="control-label" htmlFor="selectedUser">
                        User
                      </Label>
                      <Select
                        classNamePrefix="select2-selection"
                        placeholder={
                          selectedUser ? selectedUser : "Select User ..."
                        }
                        name="selectedUser"
                        onChange={handleUser}
                        options={[
                          ...(fetchUsers2.length > 0
                            ? fetchUsers2.map(e => ({
                                value: e.user_id,
                                label: `${e.role_
                                  .substring(0, 2)
                                  .toUpperCase()}: ${e.full_name} (${e.city_})`,
                              }))
                            : [{ value: "", label: "No data Found" }]),
                        ]}
                      />
                    </div>
                  </Col>
                  <Col xl={2}>
                    <div className="col-sm-auto">
                      <label className="" htmlFor="autoSizingSelect">
                        Status
                      </label>
                      <select
                        className="form-select"
                        name="change_status"
                        value={status}
                        onChange={e =>
                          setStatus(
                            e.target.value == "" ? null : e.target.value
                          )
                        }
                      >
                        <option value="">Select status ...</option>
                        <option value="new">New</option>
                        <option value="in-progress">In Progress</option>
                        <option value="dispatch">Dispatch</option>
                        <option value="delivered">Delivered</option>
                        <option value="cancel">Cancel</option>
                      </select>
                    </div>
                  </Col>
                  <Col xl={2}>
                    <label className="" htmlFor="example-date-input">
                      Date
                    </label>
                    <div className="col-md-10">
                      <input
                        className="form-control"
                        type="date"
                        id="example-date-input"
                        defaultValue={todayDate}
                        onChange={e =>
                          setTodayDate(
                            e.target.value !== "" ? e.target.value : null
                          )
                        }
                      />
                    </div>
                  </Col>
                  <Col xl={2}>
                    <Button
                      className="btn btn-primary mt-4"
                      color="primary"
                      onClick={handleFilter}
                    >
                      Filter
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          )}

          <Row className="mb-3">
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="float-end mt-3">
                    {loading ? "" : <LeadsDownload leads={orders} />}
                  </div>
                  {/* <div className="float-end mt-3">
                    {loading ? "" : <PdfLeadsDownload leads={orders} />}
                  </div> */}

                  <TableContainer
                    columns={columns}
                    data={orders}
                    isGlobalFilter={true}
                    isAddOptions={false}
                    customPageSize={10}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <div
          ref={printRef}
          className="printable"
          style={{
            display: "none",
            // margin: "0 auto" /* Center the content horizontally */,
            padding: "1mm" /* Add padding to the content */,
            // border: "1px solid #000",
            fontSize: "10px" /* Adjust font size as needed */,
            // fontFamily: 'Courier Prime ,monospace',
          }}
        >
          <div className="text-center">
            <img src={profile} alt="" width={100} />
            <br />
            <QRCode
              value={`https://ecs.com.pk/track-form?TI=${trackID}`}
              className="mt-2"
            />
            <br />
            <b>Tracking ID: </b> {fdata.ecs_track_id}
            <br />
            <strong style={{ marginTop: "-10px" }}>NTN:</strong> 7942338-0{" "}
          </div>
          {/* Receipt Details */}
          <div className="col-12 mt-2">
            <div
              className="text-center"
              style={{ backgroundColor: "black", color: "white" }}
            >
              <b>Payment</b>
            </div>
            <b>Method:</b> {fdata.pay_method}
            <div className="float-end">
              <b>Date: </b> {fdata.b_date}
            </div>
            <br />
          </div>
          <div className="col-12">
            <div
              className="text-center"
              style={{ backgroundColor: "black", color: "white" }}
            >
              <b>Detail's</b>
            </div>
            <Row>
              <Col xl="12">
                <b>ORG-DEST</b> [{SEND}-{REC}]
                <br />
                <b>SRV: </b> {fdata.service_name}
              </Col>
              <Col xl="12">
                <b>Staff</b>: {fdata.employee_id}
                <div className="float-end">
                  {DocumentMode ? <b>No. Docs: </b> : <></>}{" "}
                  {DocumentMode ? fdata.no_of_doc : <></>}
                </div>
              </Col>
            </Row>
          </div>
          <div className="col-12 ">
            <div
              className="text-center"
              style={{ backgroundColor: "black", color: "white" }}
            >
              {DocumentMode ? <b>Doc. Detail</b> : <b>Parcel Detail</b>}
            </div>
            <Row>
              <Col xl="12">
                <b>
                  {DocumentMode ? "Office Delivery: " : "Parcel Contents: "}
                </b>{" "}
                {DocumentMode
                  ? (() => {
                      switch (fdata.office_del) {
                        case "oto_":
                          return " OTO"
                        case "otd_":
                          return "OTD"
                        default:
                          return "Unknown"
                      }
                    })()
                  : fdata.content_name}
                <div className="float-end">
                  <b>
                    {DocumentMode ? "City Delivery: " : "Parcel Weight (kg): "}
                  </b>{" "}
                  {DocumentMode
                    ? (() => {
                        switch (fdata.city_del) {
                          case "wc_":
                            return "WC"
                          case "ctc_":
                            return "CTC"
                          default:
                            return "Unknown"
                        }
                      })()
                    : fdata.parcel_weight}
                </div>
              </Col>
              <Row>
                <Col xl={12}>
                  <div>
                    {""}
                    {DocumentMode ? (
                      <>
                        {" "}
                        <b>Detail :</b> {fdata.docs_detail}{" "}
                      </>
                    ) : (
                      <>
                        <b>No Of Contents:</b> {fdata.no_of_products}{" "}
                      </>
                    )}
                  </div>
                </Col>
              </Row>
            </Row>
          </div>
          <div className="col-12 ">
            <div
              className="text-center"
              style={{ backgroundColor: "black", color: "white" }}
            >
              <b>Sender Info</b>
            </div>
            <b>Name </b> {fdata.s_name}
            <br />
            <b>Ph No. </b> {fdata.s_phone}
            <br />
            <b>Address </b> {fdata.s_address}
          </div>
          <div className="col-12 ">
            <div
              className="text-center"
              style={{ backgroundColor: "black", color: "white" }}
            >
              <b>Receiver Info</b>
            </div>
            <b>Name </b> {fdata.r_name}
            <br />
            <b>Ph No. </b> {fdata.r_phone}
            <br />
            <b>Address </b> {fdata.r_address}
          </div>
          {/* Total Amount */}
          <div className="col-12 ">
            <div
              className="text-center"
              style={{ backgroundColor: "black", color: "white" }}
            >
              <b>Amount</b>
            </div>
            {cvimode ? (
              <>
                <b>Ser Charges:</b>
                <div className="float-end">
                  {DocumentMode
                    ? Number(DocAmountWithountcvi).toFixed(2)
                    : Number(ParcelChargesWithoutCVi).toFixed(2)}
                </div>
                <br />
              </>
            ) : (
              <>
                <b>Ser Charges:</b>{" "}
                <div className="float-end">
                  {DocumentMode
                    ? Number(Dcharges).toFixed(2)
                    : Number(Pcharges).toFixed(2)}
                </div>
                <br />
              </>
            )}
            {cvimode ? (
              <>
                <b>CVI (2.5%): </b>
                <div className="float-end">
                  {DocumentMode
                    ? Number(DocCviAmount).toFixed(2)
                    : Number(ParcelCviAmount).toFixed(2)}
                </div>
                <br />
                <b>GST (15%): </b>
                <div className="float-end">
                  {DocumentMode
                    ? Number(DocgstAmount).toFixed(2)
                    : Number(ParcelGSTAmount).toFixed(2)}
                </div>
              </>
            ) : (
              <>
                <b>GST (15%): </b>
                <div className="float-end">
                  {DocumentMode
                    ? Number(DocgstAmount).toFixed(2)
                    : Number(ParcelGSTAmount).toFixed(2)}
                </div>
              </>
            )}
            <br />
            <br />
            {cvimode ? (
              <>
                <b>Total: </b>{" "}
                <div className="float-end">
                  <b>
                    {DocumentMode
                      ? TotalDocCharges.toFixed(2)
                      : TotalParCharges.toFixed(2)}
                  </b>
                </div>
              </>
            ) : (
              <>
                <b>Total: </b>{" "}
                <div className="float-end">
                  <b>
                    {DocumentMode
                      ? TotalDocCharges.toFixed(2)
                      : TotalParCharges.toFixed(2)}
                  </b>
                </div>
              </>
            )}
          </div>
          <div style={{ borderTop: "1px solid gray", width: "100%" }}></div>
          <div className="text-center">
            For term's & conditions visit our site
            <br />
            ecs.com.pk
            {/* <br /> */}
            {/* Head Office No. 20/22 E,
            <br /> Mezzanine Floor, Saeed Plaza,
            <br /> Jinnah Avenue, Blue Area,
            <br /> Islamabad */}
            <br /> <strong>UAN</strong> : 051-111-327-327
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default AllCourierDetail
